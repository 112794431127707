import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const PUT_DATOS_FISCALES_REQUEST = 'PUT_DATOS_FISCALES_REQUEST'
export const PUT_DATOS_FISCALES_SUCCESS = 'PUT_DATOS_FISCALES_SUCCESS'
export const PUT_DATOS_FISCALES_FAILURE = 'PUT_DATOS_FISCALES_FAILURE'

export const PutDatosFiscalesRequest = () => {
  return {
    type: PUT_DATOS_FISCALES_REQUEST
  }
}

export const PutDatosFiscalesSuccess = (data) => {
  return {
    type: PUT_DATOS_FISCALES_SUCCESS,
    payload: data
  }
}

export const PutDatosFiscalesFailure = (err) => {
  return {
    type: PUT_DATOS_FISCALES_FAILURE,
    payload: err
  }
}

const PutDatosFiscalesAction = (idEmpresa, cuit, handleToggleFiscalView) => {
  return (dispatch) => {
    dispatch(PutDatosFiscalesRequest())
    axios({
      method: 'PUT',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}usuario/empresa/cuit-desactivar?id=${idEmpresa}`
    })
      .then(res => {
        dispatch(PutDatosFiscalesSuccess(res))
        if (res.data === "Cuit eliminado con éxito") {
          notifySuccessProgress(
            "CUIT eliminado con éxito",
            `El CUIT ${cuit} fué eliminado correctamente`
          )
          handleToggleFiscalView()
        }
      })
      .catch(err => {
        dispatch(PutDatosFiscalesFailure(err))

        dispatch(FetchRefreshToken(
          {
            status: err.response?.status,
            fnc: PutDatosFiscalesAction(idEmpresa, cuit, handleToggleFiscalView)
          }
        ))
      })
  }
}

export default PutDatosFiscalesAction
