const initialState = {
    loading: false,
    data: [],
    error: ""
}

const GetSuscripcionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_GET_SUSCRIPCIONES_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_GET_SUSCRIPCIONES_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: ""
            }
        case 'FETCH_GET_SUSCRIPCIONES_FAILURE':
            return {
                ...state,
                loading: false,
                data: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default GetSuscripcionReducer