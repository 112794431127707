import { FETCH_LOGIN_FAILURE, FETCH_LOGIN_REQUEST, FETCH_LOGIN_SUCCESS } from "./actions"

const initialState = {
  loading: false,
  user: [],
  error: {
    response: {
      status: null
    }
  }
}

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      }
    case FETCH_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: {
          response: {
            status: null
          }
        }
      }
    case FETCH_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        user: [],
        error: action.payload
      }

    default:
      return state
  }
}

export default LoginReducer