import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'

export const FETCH_NEWUSUARIOROL_REQUEST = 'FETCH_NEWUSUARIOROL_REQUEST'
export const FETCH_NEWUSUARIOROL_SUCCESS = 'FETCH_NEWUSUARIOROL_SUCCESS'
export const FETCH_NEWUSUARIOROL_FAILURE = 'FETCH_NEWUSUARIOROL_FAILURE'

export const fetchNewUsuarioRolRequest = () => {
    return {
        type: FETCH_NEWUSUARIOROL_REQUEST
    }
}

export const fetchNewUsuarioRolSuccess = (rol) => {
    return {
        type: FETCH_NEWUSUARIOROL_SUCCESS,
        payload: rol
    }

}

export const fetchNewUsuarioRolFailure = (error) => {
    return {
        type: FETCH_NEWUSUARIOROL_FAILURE,
        payload: error
    }
}

const FetchNewUsuarioRol = (data, history, handleToggleUsuarioRolesView) => {
    return (dispatch) => {
        dispatch(fetchNewUsuarioRolRequest())
        axios({
            method: 'post',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}roles/agregarUsuarioRol`,
            data: {
                id_usuario_empresa: data.id_usuario_empresa,
                email: data.email,
                rol: data.rol
            }
        })
            .then(res => {
                dispatch(fetchNewUsuarioRolSuccess(res))
                notifySuccessProgress(
                    "Rol creado",
                    `El rol ${data.nombre_rol} fue creado correctamente.`
                )
                handleToggleUsuarioRolesView()
            })
            .catch(error => {
                dispatch(fetchNewUsuarioRolFailure(error.response))
                if (error.response?.status === 404) {
                    notifyErrorProgress(
                        "Usuario no encontrado",
                        `El usuario ${data.email} no existe.`
                    )
                } else if (error.response?.status === 406) {
                    notifyErrorProgress(
                        "Usuario no encontrado",
                        `El usuario ${data.email} no existe.`
                    )
                } else if (error.response?.status === 409) {
                    notifyErrorProgress(
                        `Email ${data.email} ya se encuentra registrado en nuestra DB`
                    )
                }
                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchNewUsuarioRol(data, handleToggleUsuarioRolesView)
                    }
                ))
            })
    }
}

export default FetchNewUsuarioRol