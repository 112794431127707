import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'


export const FETCH_GET_USER_DATA_REQUEST = 'FETCH_GET_USER_DATA_REQUEST'
export const FETCH_GET_USER_DATA_SUCCESS = 'FETCH_GET_USER_DATA_SUCCESS'
export const FETCH_GET_USER_DATA_FAILURE = 'FETCH_GET_USER_DATA_FAILURE'

export const fetchGetUserDataRequest = () => {
    return {
        type: FETCH_GET_USER_DATA_REQUEST
    }
}

export const fetchGetUserDataSuccess = (cliente) => {
    return {
        type: FETCH_GET_USER_DATA_SUCCESS,
        payload: cliente
    }
}

export const fetchGetUserDataFailure = (error) => {
    return {
        type: FETCH_GET_USER_DATA_FAILURE,
        payload: error
    }
}

const FetchGetUserData = (idCliente) => {
    return (dispatch) => {
        dispatch(fetchGetUserDataRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}usuario/cliente/${idCliente}/ver-datos`
        })
            .then(res => {
                dispatch(fetchGetUserDataSuccess(res))
                console.log(res, "res")
            })
            .catch(error => {
                dispatch(fetchGetUserDataFailure(error.response))
                dispatch(FetchRefreshToken({
                    status: error.response?.status,
                    fnc: FetchGetUserData(idCliente)
                }))
                console.log(error.response, "error")
            })
    }
}

export default FetchGetUserData