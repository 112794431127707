import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_CLIENTE_REQUEST = 'FETCH_CLIENTE_REQUEST'
export const FETCH_CLIENTE_SUCCESS = 'FETCH_CLIENTE_SUCCESS'
export const FETCH_CLIENTE_FAILURE = 'FETCH_CLIENTE_FAILURE'

export const fetchEditClienteRequest = () => {
    return {
        type: FETCH_CLIENTE_REQUEST
    }
}

export const fetchEditClienteSuccess = (cliente) => {
    return {
        type: FETCH_CLIENTE_SUCCESS,
        payload: cliente
    }
}

export const fetchEditClienteFailure = (error) => {
    return {
        type: FETCH_CLIENTE_FAILURE,
        payload: error
    }
}

const FetchEditCliente = (cliente) => {
    return (dispatch) => {
      dispatch(fetchEditClienteRequest())
      axios({
        method: 'PUT',
        headers: { Authorization: `Bearer ${getToken()}` },
        url: `${BACKEND}usuario/cliente/modificar`,
        data: cliente
      })
        .then((res) => {
          dispatch(fetchEditClienteSuccess(res.data))
          notifySuccessProgress("Cliente modificado con éxito")
        })
        .catch((error) => {
          dispatch(fetchEditClienteFailure(error.response))
          dispatch(
            FetchRefreshToken({
              status: error.response?.status,
              fnc: () => FetchEditCliente(cliente)
            })
          )
        })
    }
}

export default FetchEditCliente