import {
  PUT_DATOS_BANCARIOS_REQUEST,
  PUT_DATOS_BANCARIOS_SUCCESS,
  PUT_DATOS_BANCARIOS_FAILURE
} from "../actions/updateDatosBancariosAction"

const initialState = {
  loading: false,
  data: [],
  error: ""
}

const PutDatosBancariosReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_DATOS_BANCARIOS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case PUT_DATOS_BANCARIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ""
      }
    case PUT_DATOS_BANCARIOS_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default PutDatosBancariosReducer
