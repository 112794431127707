import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_DATOS_FACTURACION_REQUEST = 'GET_DATOS_FACTURACION_REQUEST'
export const GET_DATOS_FACTURACION_SUCCESS = 'GET_DATOS_FACTURACION_SUCCESS'
export const GET_DATOS_FACTURACION_FAILURE = 'GET_DATOS_FACTURACION_FAILURE'

export const GetDatosFacturacionRequest = () => {
  return {
    type: GET_DATOS_FACTURACION_REQUEST
  }
}

export const GetDatosFacturacionSuccess = (tipo) => {
  return {
    type: GET_DATOS_FACTURACION_SUCCESS,
    payload: tipo
  }
}

export const GetDatosFacturacionFailure = (err) => {
  return {
    type: GET_DATOS_FACTURACION_FAILURE,
    payload: err
  }
}

const GetDatosFacturacion = (setFacturacion, idEmpresa) => {
  return (dispatch) => {
    dispatch(GetDatosFacturacionRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}facturacion/${idEmpresa}/tipo`
    })
      .then((res) => {
        dispatch(GetDatosFacturacionSuccess(res.data))
        setFacturacion(res.data)
      })
      .catch((err) => {
        dispatch(GetDatosFacturacionFailure(err.response))
        dispatch(FetchRefreshToken(
          {
            status: err.response?.status,
            fnc: GetDatosFacturacion(setFacturacion, idEmpresa)
          }
        ))
      })
  }
}

export default GetDatosFacturacion