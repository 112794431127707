import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GET_RECEIPT_REQUEST = 'FETCH_GET_RECEIPT_REQUEST'
export const FETCH_GET_RECEIPT_SUCCESS = 'FETCH_GET_RECEIPT_SUCCESS'
export const FETCH_GET_RECEIPT_FAILURE = 'FETCH_GET_RECEIPT_FAILURE'

export const fetchGetReceiptRequest = () => { return { type: FETCH_GET_RECEIPT_REQUEST } }
export const fetchGetReceiptSuccess = (data) => { return { type: FETCH_GET_RECEIPT_SUCCESS, payload: data } }
export const fetchGetReceiptFailure = (error) => { return { type: FETCH_GET_RECEIPT_FAILURE, payload: error } }

const GetReceiptAction = (id_recibo) => {
  return (dispatch) => {
    dispatch(fetchGetReceiptRequest())
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}facturacion/recibo`,
      params:{idRecibo : id_recibo}
    })
      .then(res => {
        dispatch(fetchGetReceiptSuccess(res.data))
      })
      .catch(err => {
        dispatch(fetchGetReceiptFailure(err))

        dispatch(FetchRefreshToken(
          {
            status: err.response?.status,
            fnc: GetReceiptAction(id_recibo)
          }
        ))
      })
  }
}

export default GetReceiptAction