import { Button, Card, CardBody } from 'reactstrap'

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
    console.log("--------> Error resetErrorBoundery", error, error.message, resetErrorBoundary)
    return (
        <div className='w-100 vh-100 d-flex flex-column align-items-center justify-content-center'>
            <Card className='p-1 m-0'>
                <CardBody className='text-align-center'>
                    <h2>¡UY! Algo salió mal...</h2>
                    {/*  <p>{error.message}</p>
                    <p>{window.location.href}</p> */}
                    <Button
                        color='primary'
                        className='mt-1'
                        onClick={resetErrorBoundary}
                    >
                        Reintentar
                    </Button>
                </CardBody>
            </Card>
        </div>
    )
}
