import axios from "axios"
import { BACKEND } from "@src/BACKEND"
import { getToken } from "@utils"
import FetchRefreshToken from "@refreshToken"

export const FETCH_FRONT_VERSION_REQUEST = "FETCH_FRONT_VERSION_REQUEST"
export const FETCH_FRONT_VERSION_SUCCESS = "FETCH_FRONT_VERSION_SUCCESS"
export const FETCH_FRONT_VERSION_FAILURE = "FETCH_FRONT_VERSION_FAILURE"

export const fetchFrontVersionRequest = () => {
  return {
    type: FETCH_FRONT_VERSION_REQUEST
  }
}

export const fetchFrontVersionSuccess = (data) => {
  return {
    type: FETCH_FRONT_VERSION_SUCCESS,
    payload: data
  }
}

export const fetchFrontVersionFailure = (error) => {
  return {
    type: FETCH_FRONT_VERSION_FAILURE,
    payload: error
  }
}

const FetchFrontVersion = (setLocalVersion, idEmpresa) => {
  return (dispatch) => {
    dispatch(fetchFrontVersionRequest())
    axios({
      method: "GET",
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}version/${idEmpresa}/front`
    })
      .then((res) => {
        dispatch(fetchFrontVersionSuccess(res))
        setLocalVersion(res.data.version)
      })
      .catch((error) => {
        dispatch(fetchFrontVersionFailure(error.response))
        dispatch(
          FetchRefreshToken({
            status: error.response?.status,
            fnc: FetchFrontVersion(setLocalVersion, idEmpresa)
          })
        )
      })
  }
}

export default FetchFrontVersion
