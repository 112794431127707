import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_OBTENER_PLAN_AFILIADO_REQUEST = 'FETCH_OBTENER_PLAN_AFILIADO_REQUEST'
export const FETCH_OBTENER_PLAN_AFILIADO_SUCCESS = 'FETCH_OBTENER_PLAN_AFILIADO_SUCCESS'
export const FETCH_OBTENER_PLAN_AFILIADO_FAILURE = 'FETCH_OBTENER_PLAN_AFILIADO_FAILURE'

export const fetchObtenerPlanAfiliadoRequest = () => {
    return {
        type: FETCH_OBTENER_PLAN_AFILIADO_REQUEST
    }
}

export const fetchObtenerPlanAfiliadoSuccess = (data) => {
    return {
        type: FETCH_OBTENER_PLAN_AFILIADO_SUCCESS,
        payload: data
    }
}

export const fetchObtenerPlanAfiliadoFailure = (error) => {
    return {
        type: FETCH_OBTENER_PLAN_AFILIADO_FAILURE,
        payload: error
    }
}

const ObtenerPlanAfiliadoAction = (idEmpresa, setPlanes) => {
    return (dispatch) => {
        dispatch(fetchObtenerPlanAfiliadoRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}afiliados/empresa/${idEmpresa}`
        })
            .then(res => {
                dispatch(fetchObtenerPlanAfiliadoSuccess(res.data))
                setPlanes(res.data)
            })
            .catch(error => {
                dispatch(fetchObtenerPlanAfiliadoFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: ObtenerPlanAfiliadoAction(idEmpresa, setPlanes)
                    }
                ))
            })
    }
}

export default ObtenerPlanAfiliadoAction