import axios from "axios"
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'


export const FETCH_POST_METODO_DE_PAGO_REQUEST =
  "FETCH_POST_METODO_DE_PAGO_REQUEST"
export const FETCH_POST_METODO_DE_PAGO_SUCCESS =
  "FETCH_POST_METODO_DE_PAGO_SUCCESS"
export const FETCH_POST_METODO_DE_PAGO_FAILURE =
  "FETCH_POST_METODO_DE_PAGO_FAILURE"

export const fetchPostMetodoDePagoRequest = () => {
  return {
    type: "FETCH_POST_METODO_DE_PAGO_REQUEST"
  }
}

export const fetchPostMetodoDePagoSuccess = (data) => {
  return {
    type: "FETCH_POST_METODO_DE_PAGO_SUCCESS",
    payload: data
  }
}

export const fetchPostMetodoDePagoFailure = (error) => {
  return {
    type: "FETCH_POST_METODO_DE_PAGO_FAILURE",
    payload: error
  }
}

export const FetchPostMetodoDePago = (id_usuario_cliente, cbu, history, RECORRIDO, callbackSetMetodo = null) => {
  return (dispatch) => {
    dispatch(fetchPostMetodoDePagoRequest())
    axios({
      method: 'post',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}metodo-de-pago/agregar?id=${id_usuario_cliente}`,
      data: { cbu }
    })
    .then((res) => {
      dispatch(fetchPostMetodoDePagoSuccess(res))
      if (res?.status === 200) {
        notifySuccessProgress(`Se cargó con éxito la CBU: ${cbu}`)

        if (callbackSetMetodo) {
          callbackSetMetodo(false)
        } else {
          if (RECORRIDO) {
            location.href = `/checkout/${RECORRIDO}`
          } else {
            if (history) {
              history.push(`/medios-cliente`)
            }
          }
        }
        

      }
    })
    .catch((err) => {
      dispatch(fetchPostMetodoDePagoFailure(err))
      if (err.response?.status === 500) notifyErrorProgress('CBU erróneo o existente en el sistema')
      dispatch(FetchRefreshToken(err.response?.status, id_usuario_cliente, cbu))
    })
  }
}
