import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_DATOS_TIPO_FACTURACION_REQUEST = 'GET_DATOS_TIPO_FACTURACION_REQUEST'
export const GET_DATOS_TIPO_FACTURACION_SUCCESS = 'GET_DATOS_TIPO_FACTURACION_SUCCESS'
export const GET_DATOS_TIPO_FACTURACION_FAILURE = 'GET_DATOS_TIPO_FACTURACION_FAILURE'

export const GetDatosTipoFacturacionRequest = () => {
    return {
        type: GET_DATOS_TIPO_FACTURACION_REQUEST
    }
}

export const GetDatosTipoFacturacionSuccess = (tipo) => {
    return {
        type: GET_DATOS_TIPO_FACTURACION_SUCCESS,
        payload: tipo
    }
}

export const GetDatosTipoFacturacionFailure = (err) => {
    return {
        type: GET_DATOS_TIPO_FACTURACION_FAILURE,
        payload: err
    }
}

const GetTipoFacturacion = (idEmpresa) => {
    return dispatch => {
        dispatch(GetDatosTipoFacturacionRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}facturacion/${idEmpresa}/tipo`
        })
            .then(res => {
                dispatch(GetDatosTipoFacturacionSuccess(res.data))
            })
            .catch(err => {
                dispatch(GetDatosTipoFacturacionFailure(err.response))

                dispatch(FetchRefreshToken(
                    {
                        status: err.response?.status,
                        fnc: GetTipoFacturacion(idEmpresa)
                    }
                ))
            })
    }
}

export default GetTipoFacturacion