import { lazy } from 'react'

const BancoRoutes = [
    {
        path: '/ajustes/banco/nueva-cuenta',
        component: lazy(() => import('@src/pages/options/banco/nuevaCuenta'))
    }

]

export default BancoRoutes