import {
    POST_ENVIAR_COMPROBANTE_REQUEST,
    POST_ENVIAR_COMPROBANTE_SUCCESS,
    POST_ENVIAR_COMPROBANTE_FAILURE
  } from '../actions/postSendInvoiceAction'
const initialState = {
    loading: false,
    data: [],
    error: ''
  }

  const postSendInvoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case POST_ENVIAR_COMPROBANTE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case POST_ENVIAR_COMPROBANTE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: ""
            }
        case POST_ENVIAR_COMPROBANTE_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default postSendInvoiceReducer