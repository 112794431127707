import {
  GET_VERSION_REQUEST,
  GET_VERSION_SUCCESS,
  GET_VERSION_FAILURE
} from '../store/versionAction'

const initialState = {
  loading: false,
  version: '',
  error: ''
}

const AppVersionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VERSION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_VERSION_SUCCESS:
      return {
        ...state,
        loading: false,
        version: action.payload,
        error: ''
      }
    case GET_VERSION_FAILURE:
      return {
        ...state,
        loading: false,
        version: '',
        error: action.payload
      }
    default:
        return state
  }
}

export default AppVersionReducer