import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_ACTIVAR_AFILIADO_REQUEST = 'FETCH_ACTIVAR_AFILIADO_REQUEST'
export const FETCH_ACTIVAR_AFILIADO_SUCCESS = 'FETCH_ACTIVAR_AFILIADO_SUCCESS'
export const FETCH_ACTIVAR_AFILIADO_FAILURE = 'FETCH_ACTIVAR_AFILIADO_FAILURE'

export const fetchActivarAfiliadoRequest = () => {
    return {
        type: FETCH_ACTIVAR_AFILIADO_REQUEST
    }
}

export const fetchActivarAfiliadoSuccess = (prod) => {
    return {
        type: FETCH_ACTIVAR_AFILIADO_SUCCESS,
        payload: prod
    }
}

export const fetchActivarAfiliadoFailure = (error) => {
    return {
        type: FETCH_ACTIVAR_AFILIADO_FAILURE,
        payload: error
    }
}

const PutActivarAfiliadoAction = (idEmpresa, idAfiliado, history) => {
    return (dispatch) => {
        dispatch(fetchActivarAfiliadoRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}afiliados/empresa/${idEmpresa}/${idAfiliado}/activar`,
            data: { idAfiliado }
        })
            .then(res => {
                dispatch(fetchActivarAfiliadoSuccess(res))
                notifySuccessProgress(
                    "Afiliado activado con exito"
                )
            })
            .catch(error => {
                dispatch(fetchActivarAfiliadoFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: PutActivarAfiliadoAction(idEmpresa, idAfiliado, history)
                    }
                ))
            })
    }
}

export default PutActivarAfiliadoAction