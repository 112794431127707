import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import MailRegistroEmpresa from '@src/mailerlite/actions/mailRegistroEmpresaAction'
import ErrorSwitch from '../functions/errorSwitch'

export const FETCH_NEW_USER_REQUEST = 'FETCH_NEW_USER_REQUEST'
export const FETCH_NEW_USER_SUCCESS = 'FETCH_NEW_USER_SUCCESS'
export const FETCH_NEW_USER_FAILURE = 'FETCH_NEW_USER_FAILURE'

export const fetchNewUserRequest = () => {
  return {
    type: FETCH_NEW_USER_REQUEST
  }
}

export const fetchNewUserSuccess = (user) => {
  return {
    type: FETCH_NEW_USER_SUCCESS,
    payload: user
  }
}

export const fetchNewUserFailure = (error) => {
  return {
    type: FETCH_NEW_USER_FAILURE,
    payload: error
  }
}

const SignInNewUser = (data, history, to) => {
  return (dispatch) => {
    dispatch(fetchNewUserRequest())
    axios({
      method: 'post',
      url: `${BACKEND}usuario/empresa/registrarse`,
      data: {
        email: data.email,
        contrasena: data.contrasena,
        nombre: data.nombre,
        apellido: data.apellido,
        pais: data.pais
      }
    })
      .then(res => {
        const messageFromBackend = res.data.msg
        dispatch(fetchNewUserSuccess(res))
        dispatch(MailRegistroEmpresa(data))
        localStorage.setItem('userData', JSON.stringify(res.data))
        localStorage.setItem('userEmpresaToken', JSON.stringify(res.data.token))
        localStorage.setItem('userCliente', JSON.stringify(res.data.cliente))
        const dataRol = res.data.adminRol
        localStorage.setItem('userRol', JSON.stringify(dataRol))
        localStorage.setItem('Rol', JSON.stringify(res.data.roles))
        if (to && /checkout/i.test(to)) {
          history.push(to)
        } else {
          history.push(`/introduccion`)
        }
        return messageFromBackend
      })
      .catch(error => {
        dispatch(fetchNewUserFailure(error.response))
        ErrorSwitch(error.response.data.msg)
      })
  }
}

export default SignInNewUser