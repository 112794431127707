import { FETCH_ONBOARDING_FAILURE, FETCH_ONBOARDING_REQUEST, FETCH_ONBOARDING_SUCCESS } from "./action"


const initialState = {
    loading: false,
    data: [],
    error: ''
}

const OnboardingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ONBOARDING_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_ONBOARDING_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: ''
            }
        case FETCH_ONBOARDING_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default OnboardingReducer