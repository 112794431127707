import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_DESACTIVAR_PLAN_REQUEST = 'FETCH_DESACTIVAR_PLAN_REQUEST'
export const FETCH_DESACTIVAR_PLAN_SUCCESS = 'FETCH_DESACTIVAR_PLAN_SUCCESS'
export const FETCH_DESACTIVAR_PLAN_FAILURE = 'FETCH_DESACTIVAR_PLAN_FAILURE'

export const fetchDesactivarPlanRequest = () => {
    return {
        type: FETCH_DESACTIVAR_PLAN_REQUEST
    }
}

export const fetchDesactivarPlanSuccess = (prod) => {
    return {
        type: FETCH_DESACTIVAR_PLAN_SUCCESS,
        payload: prod
    }
}

export const fetchDesactivarPlanFailure = (error) => {
    return {
        type: FETCH_DESACTIVAR_PLAN_FAILURE,
        payload: error
    }
}

const PutDesactivarPlanAction = (idEmpresa, idAfiliado, idPlan) => {
    return (dispatch) => {
        dispatch(fetchDesactivarPlanRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}afiliados/empresa/${idEmpresa}/${idAfiliado}/desactivarPlan`,
            data: {
                plan: idPlan
            }
        })
            .then(res => {
                console.log(res)
                dispatch(fetchDesactivarPlanSuccess(res))
                notifySuccessProgress(
                    "Plan de afiliado desactivado con exito"
                )
            })
            .catch(error => {
                dispatch(fetchDesactivarPlanFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: PutDesactivarPlanAction(idEmpresa, idAfiliado, idPlan)
                    }
                ))
            })
    }
}

export default PutDesactivarPlanAction