import { FETCH_NEWPRODUCT_FAILURE, FETCH_NEWPRODUCT_REQUEST, FETCH_NEWPRODUCT_SUCCESS } from "../actions/newProductActions"

const initialState = {
    loading: false,
    prod: [],
    error: ""
}

const NewProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NEWPRODUCT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_NEWPRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                prod: action.payload,
                error: ""
            }
        case FETCH_NEWPRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                prod: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default NewProductReducer