import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GETPRODUCTS_REQUEST = 'FETCH_GETPRODUCTS_REQUEST'
export const FETCH_GETPRODUCTS_SUCCESS = 'FETCH_GETPRODUCTS_SUCCESS'
export const FETCH_GETPRODUCTS_FAILURE = 'FETCH_GETPRODUCTS_FAILURE'

export const fetchGetProductRequest = () => {
    return {
        type: FETCH_GETPRODUCTS_REQUEST
    }
}

export const fetchGetProductSuccess = (prod) => {
    return {
        type: FETCH_GETPRODUCTS_SUCCESS,
        payload: prod
    }
}

export const fetchGetProductFailure = (error) => {
    return {
        type: FETCH_GETPRODUCTS_FAILURE,
        payload: error
    }
}
const FetchGetProduct = (setProductos, idUser) => {
    return (dispatch) => {
        dispatch(fetchGetProductRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}categoria/listado`,
            params: { idUsuarioEmpresa: idUser }
        })
            .then(res => {
                dispatch(fetchGetProductSuccess(res))
                setProductos(res.data.filter(categoria => categoria.estado_categoria).reverse())
            })
            .catch(error => {
                dispatch(fetchGetProductFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchGetProduct(setProductos, idUser)
                    }
                ))
            })
    }
}

export default FetchGetProduct