import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_EDITUSUARIOROL_REQUEST = 'FETCH_EDITUSUARIOROL_REQUEST'
export const FETCH_EDITUSUARIOROL_SUCCESS = 'FETCH_EDITUSUARIOROL_SUCCESS'
export const FETCH_EDITUSUARIOROL_FAILURE = 'FETCH_EDITUSUARIOROL_FAILURE'

export const fetchEditUsuarioRolRequest = () => {
    return {
        type: FETCH_EDITUSUARIOROL_REQUEST
    }
}

export const fetchEditUsuarioRolSuccess = (prod) => {
    return {
        type: FETCH_EDITUSUARIOROL_SUCCESS,
        payload: prod
    }
}

export const fetchEditUsuarioRolFailure = (error) => {
    return {
        type: FETCH_EDITUSUARIOROL_FAILURE,
        payload: error
    }
}

const FetchEditUsuarioRol = (data, history) => {
    return (dispatch) => {
        dispatch(fetchEditUsuarioRolRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}roles/editarUsuarioRol`,
            data: {
                
            }
        })
            .then(res => {
                dispatch(fetchEditUsuarioRolSuccess(res))
                notifySuccessProgress(
                    "Plan modificado"
                )
            })
            .catch(error => {
                dispatch(fetchEditUsuarioRolFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchEditUsuarioRol(data, history)
                    }
                ))
            })
    }
}

export default FetchEditUsuarioRol