import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_NEWPAGO_REQUEST = 'FETCH_NEWPLAN_REQUEST'
export const FETCH_NEWPAGO_SUCCESS = 'FETCH_NEWPLAN_SUCCESS'
export const FETCH_NEWPAGO_FAILURE = 'FETCH_NEWPLAN_FAILURE'

export const fetchNewPagoRequest = () => {
    return {
        type: FETCH_NEWPAGO_REQUEST
    }
}

export const fetchNewPagoSuccess = (pago) => {
    return {
        type: FETCH_NEWPAGO_SUCCESS,
        payload: pago
    }

}

export const fetchNewPagoFailure = (error) => {
    return {
        type: FETCH_NEWPAGO_FAILURE,
        payload: error
    }
}

const FetchNewPago = (data, history) => {
    return (dispatch) => {
        dispatch(fetchNewPagoRequest())
        axios({
            method: 'post',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}pagoManual/agregarPago`,
            data: {
                monto_bruto: data.precio,
                id_usuario_empresa: data.id_usuario_empresa,
                id_usuario_cliente: data.id_usuario_cliente,
                id_plan: data.id_plan
            }
        })
            .then(res => {
                dispatch(fetchNewPagoSuccess(res))
                notifySuccessProgress(
                    "Pago creado exitosamente",
                    `El pago al cliente ${res.data.apellido_cliente}, ${res.data.nombre_cliente} fue creado correctamente con un monto de $${data.precio} al plan ${res.data.nombre_plan}.`
                )
                // history.push(`/plan/ver/${data.id_plan}`)
            })
            .catch(error => {
                dispatch(fetchNewPagoFailure(error.response))
                dispatch(FetchRefreshToken(
                    FetchNewPago(data, history)
                ))
            })
        }
}

export default FetchNewPago