import { FETCH_NAV_USER_DATA_REQUEST, REMOVE_NAV_USER_DATA_REQUEST } from "./navUserDataAction"

const initialState = {
    user: [],
    img: null,
    error: ""
}

const NavUserDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NAV_USER_DATA_REQUEST:
            return {
                ...state,
                user: action.payload,
                img: action.img
            }
            case REMOVE_NAV_USER_DATA_REQUEST:
                return {
                    ...state,
                    user: action.payload

                }

        default:
            return state
    }
}

export default NavUserDataReducer