import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_DESACTIVAR_AFILIADO_REQUEST = 'FETCH_DESACTIVAR_AFILIADO_REQUEST'
export const FETCH_DESACTIVAR_AFILIADO_SUCCESS = 'FETCH_DESACTIVAR_AFILIADO_SUCCESS'
export const FETCH_DESACTIVAR_AFILIADO_FAILURE = 'FETCH_DESACTIVAR_AFILIADO_FAILURE'

export const fetchDesactivarAfiliadoRequest = () => {
    return {
        type: FETCH_DESACTIVAR_AFILIADO_REQUEST
    }
}

export const fetchDesactivarAfiliadoSuccess = (prod) => {
    return {
        type: FETCH_DESACTIVAR_AFILIADO_SUCCESS,
        payload: prod
    }
}

export const fetchDesactivarAfiliadoFailure = (error) => {
    return {
        type: FETCH_DESACTIVAR_AFILIADO_FAILURE,
        payload: error
    }
}

const PutDesactivarAfiliadoAction = (idEmpresa, idAfiliado, history) => {
    return (dispatch) => {
        dispatch(fetchDesactivarAfiliadoRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}afiliados/empresa/${idEmpresa}/${idAfiliado}/desactivar`,
            data: { idAfiliado }
        })
            .then(res => {
                dispatch(fetchDesactivarAfiliadoSuccess(res))
                notifySuccessProgress(
                    "Afiliado desactivado con exito"
                )
            })
            .catch(error => {
                dispatch(fetchDesactivarAfiliadoFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: PutDesactivarAfiliadoAction(idEmpresa, idAfiliado, history)
                    }
                ))
            })
    }
}

export default PutDesactivarAfiliadoAction