import {
  POST_DATOS_BANCARIOS_REQUEST,
  POST_DATOS_BANCARIOS_SUCCESS,
  POST_DATOS_BANCARIOS_FAILURE
} from '../actions/postDatosBancariosAction'

const initialState = {
  loading: false,
  datosBancarios: [],
  error: ""
}

const PostDatosBancariosReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_DATOS_BANCARIOS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case POST_DATOS_BANCARIOS_SUCCESS:
      return {
        ...state,
        loading: false,
        datosBancarios: action.payload,
        error: ""
      }
    case POST_DATOS_BANCARIOS_FAILURE:
      return {
        ...state,
        loading: false,
        datosBancarios: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default PostDatosBancariosReducer