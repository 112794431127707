import { UPLOAD_DNI_TRASERO_REQUEST, UPLOAD_DNI_TRASERO_SUCCESS, UPLOAD_DNI_TRASERO_FAILURE } from "../actions/uploadDniTraseroAction"

const initialState = {
    loading: false,
    dniBack: [],
    error: ""
}

const UploadDniTraseroReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_DNI_TRASERO_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPLOAD_DNI_TRASERO_SUCCESS:
            return {
                ...state,
                loading: false,
                dniBack: action.payload,
                error: ""
            }
        case UPLOAD_DNI_TRASERO_FAILURE:
            return {
                ...state,
                loading: false,
                dniBack: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default UploadDniTraseroReducer