import {
  GET_DATOS_FISCALES_REQUEST,
  GET_DATOS_FISCALES_SUCCESS,
  GET_DATOS_FISCALES_FAILURE
} from "../actions/getDatosFiscalesAction"

const initialState = {
  loading: false,
  datosFiscales: [],
  error: ""
}

const GetDatosFiscalesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATOS_FISCALES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_DATOS_FISCALES_SUCCESS:
      return {
        ...state,
        loading: false,
        datosFiscales: action.payload,
        error: ""
      }
    case GET_DATOS_FISCALES_FAILURE:
      return {
        ...state,
        loading: false,
        datosFiscales: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default GetDatosFiscalesReducer