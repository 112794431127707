import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const UPLOAD_DNI_DELANTERO_REQUEST = 'UPLOAD_DNI_DELANTERO_REQUEST'
export const UPLOAD_DNI_DELANTERO_SUCCESS = 'UPLOAD_DNI_DELANTERO_SUCCESS'
export const UPLOAD_DNI_DELANTERO_FAILURE = 'UPLOAD_DNI_DELANTERO_FAILURE'

export const UploadDniDelanteroRequest = () => {
    return {
        type: UPLOAD_DNI_DELANTERO_REQUEST
    }
}

export const UploadDniDelanteroSuccess = (dniFront) => {
    return {
        type: UPLOAD_DNI_DELANTERO_SUCCESS,
        payload: dniFront
    }

}

export const UploadDniDelanteroFailure = (error) => {
    return {
        type: UPLOAD_DNI_DELANTERO_FAILURE,
        payload: error
    }
}

const UploadDniDelanteroAction = (imgSrc, idEmpresa, idCliente, setLoadPic) => {
    return (dispatch) => {
        const formData = new FormData()
        formData.append('dni_front', imgSrc)
        dispatch(UploadDniDelanteroRequest())

        let idParam = ''
        if (idEmpresa) {
            idParam = `id_usuario_empresa=${idEmpresa}`
        } else if (idCliente) {
            idParam = `id_usuario_cliente=${idCliente}`
        }

        const urlVal = `${BACKEND}checkout/uploadDniFrontEmpresa?${idParam}`

        axios({
            method: 'post',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: urlVal,
            data: formData
        })
            .then(res => {
                dispatch(UploadDniDelanteroSuccess(res))
                if (res.status === 200) {
                    setLoadPic(true)
                    notifySuccessProgress(res.data.message)
                }
            })
            .catch(error => {
                dispatch(UploadDniDelanteroFailure(error.response))

                dispatch(FetchRefreshToken({
                    status: error.response?.status,
                    fnc: UploadDniDelanteroAction(imgSrc, idEmpresa, idCliente, setLoadPic)
                }))
            })
    }
}

export default UploadDniDelanteroAction