const initialState = { loading: false, data: [], err: "" }

const GetClientesAfiliadoReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'GET_CLIENTES_AFILIADO_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'GET_CLIENTES_AFILIADO_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.payload,
        err: ""
      }
    case 'GET_CLIENTES_AFILIADO_FAILURE':
      return {
        ...state,
        loading: false,
        data: [],
        err: action.payload
      }

    default:
      return state
  }
}

export default GetClientesAfiliadoReducer