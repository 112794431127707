import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const POST_DATOS_FISCALES_REQUEST = 'POST_DATOS_FISCALES_REQUEST'
export const POST_DATOS_FISCALES_SUCCESS = 'POST_DATOS_FISCALES_SUCCESS'
export const POST_DATOS_FISCALES_FAILURE = 'POST_DATOS_FISCALES_FAILURE'

export const PostDatosFiscalesRequest = () => {
  return {
    type: POST_DATOS_FISCALES_REQUEST
  }
}

export const PostDatosFiscalesSuccess = (data) => {
  return {
    type: POST_DATOS_FISCALES_SUCCESS,
    payload: data
  }
}

export const PostDatosFiscalesFailure = (err) => {
  return {
    type: POST_DATOS_FISCALES_FAILURE,
    payload: err
  }
}

const PostDatosFiscales = (cuit, idEmpresa, handleToggleFiscalView, setSelect) => {
  return (dispatch) => {
    dispatch(PostDatosFiscalesRequest())
    axios({
      method: 'POST',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}usuario/empresa/cuit?id=${idEmpresa}&cuit=${cuit}`
    })
      .then((res) => {
        dispatch(PostDatosFiscalesSuccess(res))
        const json = res.data
        if (json.message === "Datos fiscales agregados con éxito") notifySuccessProgress("CUIT Agregado", `El CUIT ${cuit} fue agregado correctamente`)
        if (json.message === "CUIT activado con éxito") notifySuccessProgress("CUIT Activado", `El CUIT ${cuit} fue activado nuevamente`)
        handleToggleFiscalView()
      })
      .catch((err) => {
        dispatch(PostDatosFiscalesFailure(err))
        const json = err.response.data
        if (json.message === "El cuit ingresado no es correcto") notifyErrorProgress("CUIT Erroneo o existente", `El CUIT ${cuit} no se pudo agregar al sistema`)
        setSelect(true)
        dispatch(FetchRefreshToken(
          {
            status: err.response.status,
            fnc: PostDatosFiscales(cuit, idEmpresa, handleToggleFiscalView, setSelect)
          }
        ))
      })
  }
}

export default PostDatosFiscales