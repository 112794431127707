import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const POST_ESTADO_VENDER_REQUEST = 'POST_ESTADO_VENDER_REQUEST'
export const PostEstadoVenderRequest = () => { return { type: POST_ESTADO_VENDER_REQUEST } }

export const POST_ESTADO_VENDER_SUCCESS = 'POST_ESTADO_VENDER_SUCCESS'
export const PostEstadoVenderSuccess = (data) => { return { type: POST_ESTADO_VENDER_SUCCESS, payload: data } }

export const POST_ESTADO_VENDER_FAILURE = 'POST_ESTADO_VENDER_FAILURE'
export const PostEstadoVenderFailure = (err) => { return { type: POST_ESTADO_VENDER_FAILURE, payload: err } }

const PostEstadoVender = (empresa) => {
  return (dispatch) => {
    dispatch(PostEstadoVenderRequest())
    axios({ 
      method: 'post',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}vender/${empresa}/cambiar-estado`
    })
      .then(res => {
        dispatch(PostEstadoVenderSuccess(res))
      })
      .catch(err => {
        dispatch(PostEstadoVenderFailure(err))

        dispatch(FetchRefreshToken(
          {
            status: err.response?.status,
            fnc: PostEstadoVender(empresa)
          }
        ))
      })
  }
}

export default PostEstadoVender