import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_ASOCIAR_PLAN_REQUEST = 'FETCH_ASOCIAR_PLAN_REQUEST'
export const FETCH_ASOCIAR_PLAN_SUCCESS = 'FETCH_ASOCIAR_PLAN_SUCCESS'
export const FETCH_ASOCIAR_PLAN_FAILURE = 'FETCH_ASOCIAR_PLAN_FAILURE'

export const fetchAsociarPlanRequest = () => {
    return {
        type: FETCH_ASOCIAR_PLAN_REQUEST
    }
}

export const fetchAsociarPlanSuccess = (prod) => {
    return {
        type: FETCH_ASOCIAR_PLAN_SUCCESS,
        payload: prod
    }
}

export const fetchAsociarPlanFailure = (error) => {
    return {
        type: FETCH_ASOCIAR_PLAN_FAILURE,
        payload: error
    }
}

const PutAsociarPlanAction = (idEmpresa, idAfiliado, nuevoPlan,  history) => {
    return (dispatch) => {
        dispatch(fetchAsociarPlanRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}afiliados/empresa/${idEmpresa}/${idAfiliado}/plan`,
            data: {
                plan: nuevoPlan
            }
        })
            .then(res => {
                dispatch(fetchAsociarPlanSuccess(res))
                notifySuccessProgress(
                    "Comisión actualizada con éxito"
                )
            })
            .catch(error => {
                dispatch(fetchAsociarPlanFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: PutAsociarPlanAction(idEmpresa, idAfiliado, nuevoPlan, history)
                    }
                ))
            })
    }
}

export default PutAsociarPlanAction