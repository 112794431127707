import { FETCH_EDITPRODUCT_FAILURE, FETCH_EDITPRODUCT_REQUEST, FETCH_EDITPRODUCT_SUCCESS } from "../actions/editProductAction"


const initialState = {
    loading: false,
    prod: [],
    error: ""
}

const EditProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EDITPRODUCT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_EDITPRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                prod: action.payload,
                error: ""
            }
        case FETCH_EDITPRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                prod: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default EditProductReducer