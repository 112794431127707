import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_CANCELPLAN_REQUEST = 'FETCH_CANCELPLAN_REQUEST'
export const FETCH_CANCELPLAN_SUCCESS = 'FETCH_CANCELPLAN_SUCCESS'
export const FETCH_CANCELPLAN_FAILURE = 'FETCH_CANCELPLAN_FAILURE'

export const fetchCalcelPlanRequest = () => {
    return {
        type: FETCH_CANCELPLAN_REQUEST
    }
}

export const fetchCalcelPlanSuccess = (plan) => {
    return {
        type: FETCH_CANCELPLAN_SUCCESS,
        payload: plan
    }

}

export const fetchCalcelPlanFailure = (error) => {
    return {
        type: FETCH_CANCELPLAN_FAILURE,
        payload: error
    }
}

const FetchCalcelPlan = (data, history) => {
    return (dispatch) => {
        dispatch(fetchCalcelPlanRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}plan/modificar-estado`,
            data: { idPlan: data.id_plan }
        })
            .then(res => {
                dispatch(fetchCalcelPlanSuccess(res))
                notifyErrorProgress(
                    "Plan cancelado",
                    `El plan ${data.nombre} ya no estará disponible ni podrá ser comprado por tus clientes.`
                )
                history.push(`/planes`)
            })
            .catch(error => {
                dispatch(fetchCalcelPlanFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchCalcelPlan(data, history)
                    }
                ))
            })
    }
}

export default FetchCalcelPlan