import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_ONBOARDING_REQUEST = 'FETCH_ONBOARDING_REQUEST'
export const FETCH_ONBOARDING_SUCCESS = 'FETCH_ONBOARDING_SUCCESS'
export const FETCH_ONBOARDING_FAILURE = 'FETCH_ONBOARDING_FAILURE'

export const fetchOnboardingRequest = () => {
    return {
        type: FETCH_ONBOARDING_REQUEST
    }
}

export const fetchOnboardingSuccess = (data) => {
    return {
        type: FETCH_ONBOARDING_SUCCESS,
        payload: data
    }
}

export const fetchOnboardingFailure = (error) => {
    return {
        type: FETCH_ONBOARDING_FAILURE,
        payload: error
    }
}

const fetchOnboarding = (data, history) => {
    return (dispatch) => {
        dispatch(fetchOnboardingRequest())
        axios({
            method: 'post',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}plan/crear-primer-plan`,
            data: {
                idUsuarioEmpresa: data.id_usuario_empresa,
                idUsuario: data.id_usuario,
                nombreEmpresa: data.nombreEmpresa,
                rubro: data.rubroEmpresa
            }
        })
            .then(res => {
                dispatch(fetchOnboardingSuccess(res))
                history.push(`/inicio`)
                localStorage.setItem('userData', JSON.stringify({ usuario: res.data }))
            })
            .catch(error => {
                dispatch(fetchOnboardingFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: fetchOnboarding(data, history)
                    }
                ))
            })
    }
}

export default fetchOnboarding