import {
    GET_DATOS_ROLES_REQUEST,
    GET_DATOS_ROLES_SUCCESS,
    GET_DATOS_ROLES_FAILURE
} from "../actions/getRolesAction"
  
  const initialState = {
    loading: false,
    roles: [],
    error: ""
  }
  
  const GetRolesReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_DATOS_ROLES_REQUEST:
        return {
          ...state,
          loading: true
        }
      case GET_DATOS_ROLES_SUCCESS:
        return {
          ...state,
          loading: false,
          roles: action.payload,
          error: ""
        }
      case GET_DATOS_ROLES_FAILURE:
        return {
          ...state,
          loading: false,
          roles: [],
          error: action.payload
        }
      default:
        return state
    }
  }
  
  export default GetRolesReducer