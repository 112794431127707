import { User, Home, Briefcase, Activity, Clipboard, Sliders, Users, Settings, HelpCircle, DollarSign, PieChart } from 'react-feather'

export default [
  {
    id: 'inicio',
    title: 'Inicio',
    icon: <Home size={20} />,
    navLink: '/inicio'
  },
  {
    id: 'clientes',
    title: 'Clientes',
    icon: <Users size={20} />,
    navLink: '/clientes'
  },
  {
    id: 'planes',
    title: 'Planes',
    icon: <Sliders size={20} />,
    navLink: '/planes'
  },
  {
    id: 'perfil',
    title: 'Perfil',
    icon: <User size={20} />,
    navLink: '/perfil'
  },
  {
    id: 'ayuda',
    title: 'Ayuda',
    icon: <HelpCircle size={20} />,
    navLink: '/ayuda'
  },
  {
    header: 'tu negocio'
  },
  {
    id: 'actividad',
    title: 'Actividad',
    icon: <Activity size={20} />,
    navLink: '/actividad'
  },
  {
    id: 'vender',
    title: 'Vender',
    icon: <DollarSign size={20} />,
    navLink: '/vender'
  },
  {
    id: 'metricas',
    title: 'Métricas',
    icon: <PieChart size={20}  />,
    navLink: '/metricas'
  },
  {
    id: 'dinero',
    title: 'Dinero',
    icon: <Briefcase size={20} />,
    navLink: '/dinero'
  },
  /* {
    id: 'reportes',
    title: 'Reportes',
    icon: <Clipboard size={20} />,
    navLink: '/reportes'
  }, */
  {
    id: 'ajustes',
    title: 'Ajustes',
    icon: <Settings size={20} />,
    navLink: '/ajustes'
  }

]
