import { lazy } from 'react'

const ClientesRoutes = [
    {
        path: '/clientes/nuevo',
        component: lazy(() => import('@src/pages/clientes/nuevoCliente'))
    },
    {
        path: '/clientes/ver/:id',
        component: lazy(() => import('@src/pages/clientes/detalleCliente'))
    },
    {
        path: '/clientes/factura/:id',
        component: lazy(() => import('@src/pages/clientes/invoices/previewInvoice'))
    },
    {
        path: '/clientes/print',
        layout: 'BlankLayout',
        component: lazy(() => import('@src/pages/clientes/invoices/acciones/Print'))
    },
    {
        path: '/clientes/printRecibo',
        layout: 'BlankLayout',
        component: lazy(() => import('@src/pages/clientes/invoices/acciones/PrintReceipt'))
    },
    {
        path: '/clientes/recibo/:id',
        component: lazy(() => import('@src/pages/clientes/invoices/previewReceipt'))
    },

    {
        path: '/cliente/:idCliente/suscripciones/:idSubscription',
        component: lazy(() => import('@src/pages/clientes/suscripciones/suscripciones'))
    },
    {
        path: '/cliente/:idCliente/nuevoPago',
        component: lazy(() => import('@src/pages/clientes/pagosManual/newPagoManual'))
    }
]

export default ClientesRoutes