import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_DATOS_USUARIO_ROL_REQUEST = 'GET_DATOS_USUARIO_ROL_REQUEST'
export const GET_DATOS_USUARIO_ROL_SUCCESS = 'GET_DATOS_USUARIO_ROL_SUCCESS'
export const GET_DATOS_USUARIO_ROL_FAILURE = 'GET_DATOS_USUARIO_ROL_FAILURE'

export const GetDatosUsuarioRolRequest = () => {
  return {
    type: GET_DATOS_USUARIO_ROL_REQUEST
  }
}

export const GetDatosUsuarioRolSuccess = (tipo) => {
  return {
    type: GET_DATOS_USUARIO_ROL_SUCCESS,
    payload: tipo
  }
}

export const GetDatosUsuarioRolFailure = (err) => {
  return {
    type: GET_DATOS_USUARIO_ROL_FAILURE,
    payload: err
  }
}

const GetDatosUsuarioRol = (setRoles, idEmpresa) => {
  return (dispatch) => {
    dispatch(GetDatosUsuarioRolRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}roles/obtenerUsuarioRol?id=${idEmpresa}`
    })
      .then((res) => {
        dispatch(GetDatosUsuarioRolSuccess(res.data))
        setRoles(res.data)
      })
      .catch((err) => {
        dispatch(GetDatosUsuarioRolFailure(err.response))
        dispatch(FetchRefreshToken(
          {
            status: err.response?.status,
            fnc: GetDatosUsuarioRol(setRoles, idEmpresa)
          }
        ))
      })
  }
}

export default GetDatosUsuarioRol