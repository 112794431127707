import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_EDITPRODUCT_REQUEST = 'FETCH_EDITPRODUCT_REQUEST'
export const FETCH_EDITPRODUCT_SUCCESS = 'FETCH_EDITPRODUCT_SUCCESS'
export const FETCH_EDITPRODUCT_FAILURE = 'FETCH_EDITPRODUCT_FAILURE'

export const fetchEditProductRequest = () => {
    return {
        type: FETCH_EDITPRODUCT_REQUEST
    }
}

export const fetchEditProductSuccess = (prod) => {
    return {
        type: FETCH_EDITPRODUCT_SUCCESS,
        payload: prod
    }
}

export const fetchEditProductFailure = (error) => {
    return {
        type: FETCH_EDITPRODUCT_FAILURE,
        payload: error
    }
}

const FetchEditProduct = (data, history) => {
    return (dispatch) => {
        dispatch(fetchEditProductRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}categoria/modificar`,
            data: {
                idCategoria: data.id_categoria,
                nombreCategoria: data.nombre_categoria,
                descripcionCategoria: data.descripcion_categoria
            }
        })
            .then(res => {
                dispatch(fetchEditProductSuccess(res.data))
                notifySuccessProgress(
                    "Categoría modificada",
                    `La categoría ${data.nombre_categoria} fue modificada correctamente`
                )
                history.push(`/planes`)
            })
            .catch(error => {
                dispatch(fetchEditProductFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchEditProduct(data, history)
                    }
                ))
            })
    }
}

export default FetchEditProduct