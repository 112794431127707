import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_DATOS_DETALLE_ROL_REQUEST = 'GET_DATOS_DETALLE_ROL_REQUEST'
export const GET_DATOS_DETALLE_ROL_SUCCESS = 'GET_DATOS_DETALLE_ROL_SUCCESS'
export const GET_DATOS_DETALLE_ROL_FAILURE = 'GET_DATOS_DETALLE_ROL_FAILURE'

export const GetDatosDetalleRolRequest = () => {
  return {
    type: GET_DATOS_DETALLE_ROL_REQUEST
  }
}

export const GetDatosDetalleRolSuccess = (tipo) => {
  return {
    type: GET_DATOS_DETALLE_ROL_SUCCESS,
    payload: tipo
  }
}

export const GetDatosDetalleRolFailure = (err) => {
  return {
    type: GET_DATOS_DETALLE_ROL_FAILURE,
    payload: err
  }
}

const GetDatosDetalleRol = (setRoles, idRol) => {
  return (dispatch) => {
    dispatch(GetDatosDetalleRolRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}roles/detalleRol?idRol=${idRol}`
    })
      .then((res) => {
        dispatch(GetDatosDetalleRolSuccess(res.data))
        setRoles(res.data)
      })
      .catch((err) => {
        dispatch(GetDatosDetalleRolFailure(err.response))
        dispatch(FetchRefreshToken(
          {
            status: err.response?.status,
            fnc: GetDatosDetalleRol(setRoles, idRol)
          }
        ))
      })
  }
}

export default GetDatosDetalleRol