const initialState = {
    loading: false,
    data: [],
    error: ''
  }
  
  const PutEleccionUsuarioReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'PUT_ELECCION_USUARIO_REQUEST':
        return {
          ...state,
          loading: true
        }
      case 'PUT_ELECCION_USUARIO_SUCCESS':
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: ''
        }
      case 'PUT_ELECCION_USUARIO_FAILURE':
        return {
          ...state,
          loading: false,
          data: [],
          error: action.payload
        }
  
      default:
        return state
    }
  }
  
  export default PutEleccionUsuarioReducer
  