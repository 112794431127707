import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_EDITPLAN_REQUEST = 'FETCH_EDITPLAN_REQUEST'
export const FETCH_EDITPLAN_SUCCESS = 'FETCH_EDITPLAN_SUCCESS'
export const FETCH_EDITPLAN_FAILURE = 'FETCH_EDITPLAN_FAILURE'

export const fetchEditPlanRequest = () => {
    return {
        type: FETCH_EDITPLAN_REQUEST
    }
}

export const fetchEditPlanSuccess = (prod) => {
    return {
        type: FETCH_EDITPLAN_SUCCESS,
        payload: prod
    }
}

export const fetchEditPlanFailure = (error) => {
    return {
        type: FETCH_EDITPLAN_FAILURE,
        payload: error
    }
}

const FetchEditPlan = (data, history) => {
    return (dispatch) => {
        dispatch(fetchEditPlanRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}plan/modificar`,
            data: {
                nombre: data.nombre,
                precio: data.precio,
                detalle: data.detalle,
                frecuencia: data.frecuencia,
                imagen: data.imagen,
                idCategoria: data.categoria.id_categoria,
                idPlan: data.id_plan,
                linkRedireccionamiento: data.link_redireccionamiento,
                duracionPrueba: data.duracion_prueba_gratis,
                fechaFacturacion: {
                    diaFacturacion: data.dia_facturacion,
                    mesFacturacion: data.mes_facturacion,
                    pagoMesVencido: data.pago_mes_vencido
                },
                costoInscripcion: {
                    precioInscripcion: data.precio_inscripcion
                },
                duracionPlan: {
                    cuotas: data.cuotas,
                    duracionPlanDesde: data.duracion_plan_desde,
                    duracionPlanHasta: data.duracion_plan_hasta
                },
                fechaPrimerCobro: {
                    primer_cobro: data.primer_cobro
                },
                mora: data.precioMora,
                oferta: data.oferta,
                ofertaDesde: data.oferta_desde,
                ofertaHasta: data.oferta_hasta,
                descuento: data.descuento
            }
        })
            .then(res => {
                dispatch(fetchEditPlanSuccess(res))
                notifySuccessProgress(
                    "Plan modificado",
                    `El plan ${data.nombre} fue modificado correctamente.`
                )
                history.push(`/plan/ver/${data.id_plan}`)
            })
            .catch(error => {
                dispatch(fetchEditPlanFailure(error.response))
								console.log(error.response, ' error action ')
                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchEditPlan(data, history)
                    }
                ))
            })
    }
}

export default FetchEditPlan