import { lazy } from 'react'

const ProdRoutes = [

    {
        path: '/categoria/nuevo',
        component: lazy(() => import('@src/pages/planes/productTab/newProduct'))
    },
    {
        path: '/categoria/editar/:id',
        component: lazy(() => import('@src/pages/planes/productTab/editProd'))
    }

]

export default ProdRoutes