const initialState = { loading: false, user: [], error: "" }

const SelectAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECT_ACCOUNT_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'SELECT_ACCOUNT_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ""
      }
    case 'SELECT_ACCOUNT_FAILURE':
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default SelectAccountReducer