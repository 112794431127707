import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_EDITROL_REQUEST = 'FETCH_EDITROL_REQUEST'
export const FETCH_EDITROL_SUCCESS = 'FETCH_EDITROL_SUCCESS'
export const FETCH_EDITROL_FAILURE = 'FETCH_EDITROL_FAILURE'

export const fetchEditRolRequest = () => {
    return {
        type: FETCH_EDITROL_REQUEST
    }
}

export const fetchEditRolSuccess = (prod) => {
    return {
        type: FETCH_EDITROL_SUCCESS,
        payload: prod
    }
}

export const fetchEditRolFailure = (error) => {
    return {
        type: FETCH_EDITROL_FAILURE,
        payload: error
    }
}

const FetchEditRol = (roles, history) => {
    return (dispatch) => {
        dispatch(fetchEditRolRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}roles/editarRol`,
            data: roles
        })
            .then(res => {
                dispatch(fetchEditRolSuccess(res))
                notifySuccessProgress(
                    "Rol modificado con exito"
                )
            })
            .catch(error => {
                dispatch(fetchEditRolFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchEditRol(roles, history)
                    }
                ))
            })
    }
}

export default FetchEditRol