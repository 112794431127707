import axios from 'axios'
import { BACKEND } from '@src/BACKEND'

export const FETCH_RESTORE_EMAIL_REQUEST = 'FETCH_RESTORE_EMAIL_REQUEST'
export const FETCH_RESTORE_EMAIL_SUCCESS = 'FETCH_RESTORE_EMAIL_SUCCESS'
export const FETCH_RESTORE_EMAIL_FAILURE = 'FETCH_RESTORE_EMAIL_FAILURE'

export const fetchRestoreEmailRequest = () => {
    return {
        type: FETCH_RESTORE_EMAIL_REQUEST
    }
}

export const fetchRestoreEmailSuccess = (data) => {
    return {
        type: FETCH_RESTORE_EMAIL_SUCCESS,
        payload: data
    }
}

export const fetchRestoreEmailFailure = (error) => {
    return {
        type: FETCH_RESTORE_EMAIL_FAILURE,
        payload: error
    }
}

const FetchRestoreEmail = (setEmail, emailUser) => {
    return (dispatch) => {
        dispatch(fetchRestoreEmailRequest())
        axios({
            method: 'POST',
            url: `${BACKEND}usuario/${emailUser}/solicitar-contrasena`
        })
            .then(res => {
                dispatch(fetchRestoreEmailSuccess(res))
                setEmail("")
            })
            .catch(error => {
                dispatch(fetchRestoreEmailFailure(error.response))
            })
    }
}

export default FetchRestoreEmail