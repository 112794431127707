import { FETCH_GET_AYUDA_EMPRESA_FAILURE, FETCH_GET_AYUDA_EMPRESA_REQUEST, FETCH_GET_AYUDA_EMPRESA_SUCCESS } from "../actions/getAyudaEmpresa"

const initialState = {
    loading: false,
    ayuda: [],
    error: ""
}

const GetAyudaEmpresaReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GET_AYUDA_EMPRESA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_GET_AYUDA_EMPRESA_SUCCESS:
            return {
                ...state,
                loading: false,
                ayuda: action.payload,
                error: ""
            }
        case FETCH_GET_AYUDA_EMPRESA_FAILURE:
            return {
                ...state,
                loading: false,
                ayuda: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default GetAyudaEmpresaReducer
