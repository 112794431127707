import axios from 'axios'
import { BACKEND } from '../../BACKEND'
import { getToken } from '@utils'

export const FETCH_REFRESH_TOKEN_REQUEST = 'FETCH_REFRESH_TOKEN_REQUEST'
export const FETCH_REFRESH_TOKEN_SUCCESS = 'FETCH_REFRESH_TOKEN_SUCCESS'
export const FETCH_REFRESH_TOKEN_FAILURE = 'FETCH_REFRESH_TOKEN_FAILURE'

export const fetchRefreshTokenRequest = () => {
    return {
        type: FETCH_REFRESH_TOKEN_REQUEST
    }
}

export const fetchRefreshTokenSuccess = (token) => {
    return {
        type: FETCH_REFRESH_TOKEN_SUCCESS,
        payload: token
    }
}

export const fetchRefreshTokenFailure = (error) => {
    return {
        type: FETCH_REFRESH_TOKEN_FAILURE,
        payload: error
    }
}


const FetchRefreshToken = ({ status, fnc }) => {
    return (dispatch) => {
        if (status === 403) {
            dispatch(fetchRefreshTokenRequest())
            axios({
                method: 'GET',
                headers: { Authorization: `Bearer ${getToken()}` },
                url: `${BACKEND}autorizacion/actualizar-token`
            })
                .then(res => {
                    dispatch(fetchRefreshTokenSuccess(res))
                    localStorage.setItem('userEmpresaToken', JSON.stringify(res.data.token))
                    dispatch(fnc)
                })
                .catch(error => {
                    dispatch(fetchRefreshTokenFailure(error.response))
                    localStorage.removeItem('userData')
                    localStorage.removeItem('userEmpresaToken')
                    // window.location = "/no-autorizado"
                })
        }
    }

}

export default FetchRefreshToken