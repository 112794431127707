import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const UPLOAD_DNI_TRASERO_REQUEST = 'UPLOAD_DNI_TRASERO_REQUEST'
export const UPLOAD_DNI_TRASERO_SUCCESS = 'UPLOAD_DNI_TRASERO_SUCCESS'
export const UPLOAD_DNI_TRASERO_FAILURE = 'UPLOAD_DNI_TRASERO_FAILURE'

export const UploadDniTraseroRequest = () => {
    return {
        type: UPLOAD_DNI_TRASERO_REQUEST
    }
}

export const UploadDniTraseroSuccess = (dniBack) => {
    return {
        type: UPLOAD_DNI_TRASERO_SUCCESS,
        payload: dniBack
    }

}

export const UploadDniTraseroFailure = (error) => {
    return {
        type: UPLOAD_DNI_TRASERO_FAILURE,
        payload: error
    }
}

const UploadDniTraseroAction = (imgSrc, idEmpresa, idCliente, setLoadPic) => {
    return (dispatch) => {
        const formData = new FormData()
        formData.append('dni_back', imgSrc)
        dispatch(UploadDniTraseroRequest())

        let idParam = ''
        if (idEmpresa) {
            idParam = `id_usuario_empresa=${idEmpresa}`
        } else if (idCliente) {
            idParam = `id_usuario_cliente=${idCliente}`
        }

        const urlVal = `${BACKEND}checkout/uploadDniBackEmpresa?${idParam}`

        axios({
            method: 'post',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: urlVal,
            data: formData
        })
            .then(res => {
                dispatch(UploadDniTraseroSuccess(res))
                if (res.status === 200) {
                    setLoadPic(true)
                    notifySuccessProgress(
                        res.data.message
                    )
                }
            })
            .catch(error => {
                dispatch(UploadDniTraseroFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: UploadDniTraseroAction(imgSrc, idEmpresa, setLoadPic)
                    }
                ))
            })
    }
}

export default UploadDniTraseroAction