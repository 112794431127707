import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_DETAILPLAN_REQUEST = 'FETCH_DETAILPLAN_REQUEST'
export const FETCH_DETAILPLAN_SUCCESS = 'FETCH_DETAILPLAN_SUCCESS'
export const FETCH_DETAILPLAN_FAILURE = 'FETCH_DETAILPLAN_FAILURE'

export const fetchGetDetailPlanRequest = () => {
    return {
        type: FETCH_DETAILPLAN_REQUEST
    }
}

export const fetchGetDetailPlanSuccess = (prod) => {
    return {
        type: FETCH_DETAILPLAN_SUCCESS,
        payload: prod
    }
}

export const fetchGetDetailPlanFailure = (error) => {
    return {
        type: FETCH_DETAILPLAN_FAILURE,
        payload: error
    }
}

const FetchGetDetailPlan = (setPlan, id) => {
    return (dispatch) => {
        dispatch(fetchGetDetailPlanRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}plan/ver-plan?idPlan=${id}`
        })
            .then(res => {
                dispatch(fetchGetDetailPlanSuccess(res))
                setPlan(res.data)
            })
            .catch(error => {
                dispatch(fetchGetDetailPlanFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchGetDetailPlan(setPlan, id)
                    }
                ))
            })
    }
}

export default FetchGetDetailPlan