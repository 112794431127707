import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import NavUserData from '../../../../../@core/layouts/components/navbar/store/storeUserData/navUserDataAction'

export const FETCH_CHANGE_PROFILE_AVATAR_REQUEST = 'FETCH_CHANGE_PROFILE_AVATAR_REQUEST'
export const FETCH_CHANGE_PROFILE_AVATAR_SUCCESS = 'FETCH_CHANGE_PROFILE_AVATAR_SUCCESS'
export const FETCH_CHANGE_PROFILE_AVATAR_FAILURE = 'FETCH_CHANGE_PROFILE_AVATAR_FAILURE'

export const fetchChangeProfileAvatarRequest = () => {
	return {
		type: FETCH_CHANGE_PROFILE_AVATAR_REQUEST
	}
}

export const fetchChangeProfileAvatarSuccess = (user) => {
	return {
		type: FETCH_CHANGE_PROFILE_AVATAR_SUCCESS,
		payload: user
	}
}

export const fetchChangeProfileAvatarFailure = (error) => {
	return {
		type: FETCH_CHANGE_PROFILE_AVATAR_FAILURE,
		payload: error
	}
}

const ChangeProfileAvatar = (data, img, imgRender) => {
	return (dispatch) => {
		const formData = new FormData()
		formData.append('imagen_perfil', img)
		const {
			id_usuario_empresa
		} = data.usuario
		formData.append('id_usuario_empresa', id_usuario_empresa)
		dispatch(fetchChangeProfileAvatarRequest())
		axios.put(
			`${BACKEND}usuario/empresa/modificarAvatar`,
			formData,
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
					'Content-Type': 'multipart/form-data'
				}
			}
		)
			.then(res => {
				const userData = JSON.parse(localStorage.getItem('userData'))
				userData.usuario.imagen_empresa = res.url
				localStorage.setItem('userData', JSON.stringify({ ...data, usuario: { ...data.usuario, imagen_empresa: res.data.url } }))
				dispatch(fetchChangeProfileAvatarSuccess(res))
				dispatch(NavUserData(res.data.url))
				notifySuccessProgress(
					"Avatar modificado con exito"
				)
			})
			.catch(err => {
				dispatch(fetchChangeProfileAvatarFailure(err))
				dispatch(FetchRefreshToken(
					{
						status: err.response?.status,
						fnc: ChangeProfileAvatar(data, img, imgRender)
					}
				))
			})
	}
}

export default ChangeProfileAvatar