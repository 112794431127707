import {
  BACKEND
} from "@src/BACKEND"
import axios from "axios"
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_FACTURAS_REQUEST = 'GET_FACTURAS_REQUEST'
export const GET_FACTURAS_SUCCESS = 'GET_FACTURAS_SUCCESS'
export const GET_FACTURAS_ERROR = 'GET_FACTURAS_ERROR'

const GetFacturasRequest = () => {
  return {
    type: GET_FACTURAS_REQUEST
  }
}

const GetFacturasSuccess = (facturas) => {
  return {
    type: GET_FACTURAS_SUCCESS,
    payload: facturas
  }
}

const GetFacturasError = (error) => {
  return {
    type: GET_FACTURAS_ERROR,
    payload: error
  }
}

const GetFacturas = (idEmpresa) => {
  return (dispatch) => {
    dispatch(GetFacturasRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}facturacion/${idEmpresa}/facturas`
    })
      .then((res) => {
        dispatch(GetFacturasSuccess(res))
      })
      .catch((error) => {
        dispatch(GetFacturasError(error.response))

        dispatch(FetchRefreshToken(
          {
            status: error.response?.status,
            fnc: GetFacturas(idEmpresa)
          }
        ))
      })
  }
}

export default GetFacturas
