import { FETCH_COMISION_AFILIADO_FAILURE, FETCH_COMISION_AFILIADO_REQUEST, FETCH_COMISION_AFILIADO_SUCCESS } from "../actions/putCambiarComisionAfiliadoAction"

const initialState = {
    loading: false,
    comision: [],
    error: ""
}

const PutComisionAfiliadoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMISION_AFILIADO_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_COMISION_AFILIADO_SUCCESS:
            return {
                ...state,
                loading: false,
                comision: action.payload,
                error: ""
            }
        case FETCH_COMISION_AFILIADO_FAILURE:
            return {
                ...state,
                loading: false,
                comision: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default PutComisionAfiliadoReducer