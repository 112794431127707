import { lazy } from 'react'

const PlanRoutes = [
    {
        path: '/plan/nuevo',
        component: lazy(() => import('@src/pages/planes/planesTab/newPlan'))
    },
    {
        path: '/plan/ver/:id',
        component: lazy(() => import('@src/pages/planes/planesTab/detailPlan'))
    },
    {
        path: '/plan/editar/:id',
        component: lazy(() => import('@src/pages/planes/planesTab/editPlan'))
    }
    // {
    //     path: '/plan/vender',
    //     component: lazy(() => import('../../pages/vender/venderTab'))
    // }
    

]

export default PlanRoutes