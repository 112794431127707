import axios from "axios"
import { BACKEND } from "@src/BACKEND"
import { toast } from "react-toastify"
import { Check, X } from "react-feather"
import Avatar from "@components/avatar"
import { getToken } from "@utils"
import FetchRefreshToken from "@refreshToken"
export const FETCH_ASOCIAR_QR_REQUEST = "FETCH_ASOCIAR_QR_REQUEST"
export const FETCH_ASOCIAR_QR_SUCCESS = "FETCH_ASOCIAR_QR_SUCCESS"
export const FETCH_ASOCIAR_QR_FAILURE = "FETCH_ASOCIAR_QR_FAILURE"

const ToastSuccess = () => (
  <div className="toastify-header pb-0">
    <div className="title-wrapper">
      <Avatar size="sm" color="success" icon={<Check />} />
      <h6 className="toast-title">¡QR asociado correctamente!</h6>
    </div>
  </div>
)

const ToastFail = () => (
  <div className="toastify-header pb-0">
    <div className="title-wrapper">
      <Avatar size="sm" color="danger" icon={<X />} />
      <h6 className="toast-title" style={{ color: "#ea5455" }}>
        El QR ya está en uso
      </h6>
    </div>
  </div>
)

const handleQrAsociardo = () => {
  toast.success(<ToastSuccess />, {
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false
  })
}

const handleQrFail = () => {
  toast.success(<ToastFail />, {
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false
  })
}

export const fetchNewQrRequest = () => {
  return {
    type: FETCH_ASOCIAR_QR_REQUEST
  }
}

export const fetchNewQrSuccess = (qr) => {
  return {
    type: FETCH_ASOCIAR_QR_SUCCESS,
    payload: qr
  }
}

export const fetchNewQrFailure = (error) => {
  return {
    type: FETCH_ASOCIAR_QR_FAILURE,
    payload: error
  }
}

const FetchNewQr = (qr, empresa, setQr, history) => {
  return (dispatch) => {
    dispatch(fetchNewQrRequest())
    axios({
      method: 'post',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}qr/asociar`,
      data: { idQr: qr, idEmpresa: empresa }
    })
      .then((res) => {
        dispatch(fetchNewQrSuccess(res))
        handleQrAsociardo()
        setQr("")
        history.push("/vender")
      })

      .catch((error) => {
        dispatch(fetchNewQrFailure(error.response))
        if (error.response?.status === 400) handleQrFail()

        dispatch(
          FetchRefreshToken({
            status: error.response?.status,
            fnc: FetchNewQr(qr, empresa, setQr, history)
          })
        )
      })
  }
}

export default FetchNewQr
