import {
    GET_DATOS_DETALLE_ROL_REQUEST,
    GET_DATOS_DETALLE_ROL_SUCCESS,
    GET_DATOS_DETALLE_ROL_FAILURE
} from "../actions/detalleRolAction"
  
  const initialState = {
    loading: false,
    rol: [],
    error: ""
  }
  
  const GetDetalleRolReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_DATOS_DETALLE_ROL_REQUEST:
        return {
          ...state,
          loading: true
        }
      case GET_DATOS_DETALLE_ROL_SUCCESS:
        return {
          ...state,
          loading: false,
          rol: action.payload,
          error: ""
        }
      case GET_DATOS_DETALLE_ROL_FAILURE:
        return {
          ...state,
          loading: false,
          rol: [],
          error: action.payload
        }
      default:
        return state
    }
  }
  
  export default GetDetalleRolReducer