import {
    FETCH_POST_METODO_DE_PAGO_REQUEST,
    FETCH_POST_METODO_DE_PAGO_SUCCESS,
    FETCH_POST_METODO_DE_PAGO_FAILURE
  } from "../../../actions/actions-cliente/medios-cliente/mediosPostAction"
  
  const initialState = {
    loading: false,
    metodoDePago: [],
    error: ""
  }
  
  const PostMetodoDePagoReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_POST_METODO_DE_PAGO_REQUEST:
        return {
          ...state,
          loading: true
        }
      case FETCH_POST_METODO_DE_PAGO_SUCCESS:
        return {
          ...state,
          loading: false,
          metodoDePago: action.payload,
          error: ""
        }
      case FETCH_POST_METODO_DE_PAGO_FAILURE:
        return {
          ...state,
          loading: false,
          metodoDePago: [],
          error: action.payload
        }
  
      default:
        return state
    }
  }
  
  export default PostMetodoDePagoReducer
  