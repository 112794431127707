import { notifyErrorProgress } from '../../../../components/Toast/failedToast'

export default function ErrorSwitch (message) {
  switch (message) {
    case 'El nombre debe tener menos de 30 caracteres':
      notifyErrorProgress('El nombre debe tener menos de 30 caracteres')
      break
    case 'El apellido debe tener menos de 30 caracteres':
      notifyErrorProgress('El apellido debe tener menos de 30 caracteres')
      break
    case 'La contraseña debe tener por lo menos 8 caracteres':
      notifyErrorProgress('La contraseña debe tener por lo menos 8 caracteres')
      break
    case 'La contraseña debe contener al menos un caracter':
      notifyErrorProgress('La contraseña debe contener al menos un caracter')
      break
    case 'La contraseña debe contener al menos un número':
      notifyErrorProgress('La contraseña debe contener al menos un número')
      break
    case 'La contraseña debe tener menos de 20 caracteres':
      notifyErrorProgress('La contraseña debe tener menos de 20 caracteres')
      break
    case 'Formato de email incorrecto':
      notifyErrorProgress('Formato de email incorrecto')
      break
    case 'El email debe tener menos de 45 caracteres':
      notifyErrorProgress('El email debe tener menos de 45 caracteres')
      break
  }
}