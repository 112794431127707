import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_DETALLE_SUSCRIPCION_REQUEST = 'FETCH_DETALLE_SUSCRIPCION_REQUEST'
export const FETCH_DETALLE_SUSCRIPCION_SUCCESS = 'FETCH_DETALLE_SUSCRIPCION_SUCCESS'
export const FETCH_DETALLE_SUSCRIPCION_FAILURE = 'FETCH_DETALLE_SUSCRIPCION_FAILURE'

export const fetchDetalleSuscripcionRequest = () => {
    return {
        type: FETCH_DETALLE_SUSCRIPCION_REQUEST
    }
}

export const fetchDetalleSuscripcionSuccess = (suscripcion) => {
    return {
        type: FETCH_DETALLE_SUSCRIPCION_SUCCESS,
        payload: suscripcion
    }
}

export const fetchDetalleSuscripcionFailure = (error) => {
    return {
        type: FETCH_DETALLE_SUSCRIPCION_FAILURE,
        payload: error
    }
}

const FetchDetalleSuscripcion = (setSuscripcion, idSubscription) => {
    return (dispatch) => {
        dispatch(fetchDetalleSuscripcionRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}empresa/suscripcion/detalle`,
            params:{ idSuscripcion: idSubscription}
        })
            .then(res => {
                dispatch(fetchDetalleSuscripcionSuccess(res))
                setSuscripcion(res.data)
            })
            .catch(error => {
                dispatch(fetchDetalleSuscripcionFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchDetalleSuscripcion(setSuscripcion, idSubscription)
                    }
                ))
            })
    }
}

export default FetchDetalleSuscripcion