import {
  GET_DATOS_FACTURACION_REQUEST,
  GET_DATOS_FACTURACION_SUCCESS,
  GET_DATOS_FACTURACION_FAILURE
} from '../actions/getDatosFacturacion'

const initialState = {
  loading: false,
  datosFacturacion: [],
  error: ""
}

const GetDatosFacturacionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATOS_FACTURACION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_DATOS_FACTURACION_SUCCESS:
      return {
        ...state,
        loading: false,
        datosFacturacion: action.payload,
        error: ""
      }
    case GET_DATOS_FACTURACION_FAILURE:
      return {
        ...state,
        loading: false,
        datosFacturacion: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default GetDatosFacturacionReducer