import { User, Home, Sliders, HelpCircle, DollarSign} from 'react-feather'

export default [
  {
    id: 'inicio',
    title: 'Inicio',
    icon: <Home size={20} />,
    navLink: '/inicio-cliente'
  },

  {
    id: 'suscripciones',
    title: 'Suscripciones',
    icon: <Sliders size={20} />,
    navLink: '/suscripciones-cliente'
},
{
  id: 'medios de pago',
  title: 'Medios de pago',
  icon: <DollarSign size={20} />,
  navLink: '/medios-cliente'
},

{

},
  {
      id: 'perfil',
      title: 'Perfil',
      icon: <User size={20} />,
      navLink: '/perfil-cliente'
    },
    {
    id: 'ayuda',
    title: 'Ayuda',
    icon: <HelpCircle size={20} />,
    navLink: '/cliente-ayuda'
  }
]
