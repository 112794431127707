import { FETCH_ASOCIAR_PLAN_FAILURE, FETCH_ASOCIAR_PLAN_REQUEST, FETCH_ASOCIAR_PLAN_SUCCESS } from "../actions/putAsociarPlanAction"

const initialState = {
    loading: false,
    plan: [],
    error: ""
}

const PutAsociarPlanReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ASOCIAR_PLAN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_ASOCIAR_PLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                plan: action.payload,
                error: ""
            }
        case FETCH_ASOCIAR_PLAN_FAILURE:
            return {
                ...state,
                loading: false,
                plan: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default PutAsociarPlanReducer