import { UPLOAD_SELFIE_REQUEST, UPLOAD_SELFIE_SUCCESS, UPLOAD_SELFIE_FAILURE } from "../actions/uploadSelfieAction"

const initialState = {
    loading: false,
    selfie: [],
    error: ""
}

const UploadSelfieReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_SELFIE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case UPLOAD_SELFIE_SUCCESS:
            return {
                ...state,
                loading: false,
                selfie: action.payload,
                error: ""
            }
        case UPLOAD_SELFIE_FAILURE:
            return {
                ...state,
                loading: false,
                selfie: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default UploadSelfieReducer