import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_NEWPRODUCT_REQUEST = 'FETCH_NEWPRODUCT_REQUEST'
export const FETCH_NEWPRODUCT_SUCCESS = 'FETCH_NEWPRODUCT_SUCCESS'
export const FETCH_NEWPRODUCT_FAILURE = 'FETCH_NEWPRODUCT_FAILURE'

export const fetchNewProductRequest = () => {
    return {
        type: FETCH_NEWPRODUCT_REQUEST
    }
}

export const fetchNewProductSuccess = (prod) => {
    return {
        type: FETCH_NEWPRODUCT_SUCCESS,
        payload: prod
    }

}

export const fetchNewProductFailure = (error) => {
    return {
        type: FETCH_NEWPRODUCT_FAILURE,
        payload: error
    }
}

const FetchNewProduct = (data, history, idUser) => {
    return (dispatch) => {
        dispatch(fetchNewProductRequest())
        axios({
            method: 'POST',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}categoria/crear`,
            data: {
                idUsuarioEmpresa: idUser,
                nombreCategoria: data.nombre,
                descripcionCategoria: data.descript
            }
        })
            .then(res => {
                dispatch(fetchNewProductSuccess(res))
                notifySuccessProgress(
                    "Categoría creada",
                    `La categoría ${data.nombre} fue creada correctamente`
                )
                history.push(`/planes`)
            })
            .catch(error => {
                dispatch(fetchNewProductFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchNewProduct(data, history, idUser)
                    }
                ))
            })
    }
}

export default FetchNewProduct