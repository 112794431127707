// ** Vertical Menu Components
import VerticalNavMenuLink from './VerticalNavMenuLink'
import VerticalNavMenuGroup from './VerticalNavMenuGroup'
import VerticalNavMenuSectionHeader from './VerticalNavMenuSectionHeader'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GetValidationMenuAction from './store/actions/GetValidationMenuAction'

// ** Utils
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@layouts/utils'

const VerticalMenuNavItems = props => {
  const dispatch = useDispatch()
  const [userData] = useState(JSON.parse(localStorage.getItem('userData')))
  const [viewState, setViewState] = useState()

  useEffect(() => {
    dispatch(GetValidationMenuAction(setViewState, userData.usuario.id_usuario_empresa))
  }, [])
  
  // ** Components Object
  const Components = {
    VerticalNavMenuSectionHeader,
    VerticalNavMenuGroup,
    VerticalNavMenuLink
  }

  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)]

    return <TagName key={item.id || item.header} item={item} {...props} viewState={viewState} />
  })

  return RenderNavItems
}

export default VerticalMenuNavItems
