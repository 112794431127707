import axios from 'axios'
import { BACKEND } from '@src/BACKEND'

import { toast } from 'react-toastify'
import { Check } from 'react-feather'
import Avatar from '@components/avatar'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'
import NavUserData from '@layouts/components/navbar/store/storeUserData/navUserDataAction'

export const FETCH_CHANGE_PROFILE_IMG_REQUEST = 'FETCH_CHANGE_PROFILE_IMG_REQUEST'
export const FETCH_CHANGE_PROFILE_IMG_SUCCESS = 'FETCH_CHANGE_PROFILE_IMG_SUCCESS'
export const FETCH_CHANGE_PROFILE_IMG_FAILURE = 'FETCH_CHANGE_PROFILE_IMG_FAILURE'

const ToastSuccess = () => (
	<div className='toastify-header pb-0'>
		<div className='title-wrapper'>
			<Avatar size='sm' color='success' icon={<Check />} />
			<h6 className='toast-title'>¡imagen modificada correctamente!</h6>
		</div>
	</div>
)

const handleImgSubida = () => {
	toast.success(<ToastSuccess />, {
		autoClose: 3000,
		hideProgressBar: true,
		closeButton: false
	})
}

export const fetchChangeProfileImgRequest = () => {
	return {
		type: FETCH_CHANGE_PROFILE_IMG_REQUEST
	}
}

export const fetchChangeProfileImgSuccess = (user) => {
	return {
		type: FETCH_CHANGE_PROFILE_IMG_SUCCESS,
		payload: user
	}
}

export const fetchChangeProfileImgFailure = (error) => {
	return {
		type: FETCH_CHANGE_PROFILE_IMG_FAILURE,
		payload: error
	}
}

const ChangeProfileImg = (data, img, imgRender) => {
	return (dispatch) => {
		const formData = new FormData()
		formData.append('imagen_perfil', img)
		const {
			id_usuario_empresa,
			nombre,
			nombre_gerente,
			apellido_gerente,
			pais,
			provincia,
			telefono_gerente,
			rubro,
			rol,
			nombre_resumen,
			descripcion,
			mora,
			baja
		} = data.usuario
		formData.append('id_usuario_empresa', id_usuario_empresa)
		formData.append('nombre', nombre)
		formData.append('nombre_gerente', nombre_gerente)
		formData.append('apellido_gerente', apellido_gerente)
		formData.append('pais', pais)
		formData.append('provincia', provincia)
		formData.append('telefono_gerente', telefono_gerente)
		formData.append('rubro', rubro)
		formData.append('rol', rol)
		formData.append('nombre_resumen', nombre_resumen)
		formData.append('descripcion', descripcion)
		formData.append('mora', mora)
		formData.append('baja', baja)
		dispatch(fetchChangeProfileImgRequest())
		axios.put(
			`${BACKEND}usuario/empresa/modificar`,
			formData,
			{
				headers: {
					Authorization: `Bearer ${getToken()}`,
					'Content-Type': 'multipart/form-data'
				}
			}
		)
			.then(res => {
				dispatch(fetchChangeProfileImgSuccess(res))
				localStorage.setItem('userData', JSON.stringify({ ...data, usuario: { ...data.usuario, imagen_empresa: res.data.usuario.imagen_empresa } }))
				handleImgSubida()
				dispatch(NavUserData(imgRender))
			})
			.catch(err => {
				dispatch(fetchChangeProfileImgFailure(err))
				dispatch(FetchRefreshToken(
					{
						status: err.response?.status,
						fnc: ChangeProfileImg(data, img, imgRender)
					}
				))
			})
	}
}

export default ChangeProfileImg