import { FETCH_GET_QR_FAILURE, FETCH_GET_QR_REQUEST, FETCH_GET_QR_SUCCESS } from "../actions/getQrEmpresaAction"

const initialState = {
    loading: false,
    qr: [],
    error: ""
}

const GetQrReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GET_QR_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_GET_QR_SUCCESS:
            return {
                ...state,
                loading: false,
                qr: action.payload,
                error: ""
            }
        case FETCH_GET_QR_FAILURE:
            return {
                ...state,
                loading: false,
                qr: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default GetQrReducer