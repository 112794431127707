import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_DETAILPAGO_REQUEST = 'FETCH_DETAILPAGO_REQUEST'
export const FETCH_DETAILPAGO_SUCCESS = 'FETCH_DETAILPAGO_SUCCESS'
export const FETCH_DETAILPAGO_FAILURE = 'FETCH_DETAILPAGO_FAILURE'

export const fetchGetDetailPagoRequest = () => {
    return {
        type: FETCH_DETAILPAGO_REQUEST
    }
}

export const fetchGetDetailPagoSuccess = (prod) => {
    return {
        type: FETCH_DETAILPAGO_SUCCESS,
        payload: prod
    }
}

export const fetchGetDetailPagoFailure = (error) => {
    return {
        type: FETCH_DETAILPAGO_FAILURE,
        payload: error
    }
}

const FetchGetDetailPago = (setDetallePago, idSuscripcion) => {
    return (dispatch) => {
        dispatch(fetchGetDetailPagoRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}cliente/detallePago?idSuscripcion=${idSuscripcion}`
        })
            .then(res => {
                dispatch(fetchGetDetailPagoSuccess(res))
                setDetallePago(res.data)
            })
            .catch(error => {
                dispatch(fetchGetDetailPagoFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: () => FetchGetDetailPago(setDetallePago, idSuscripcion)
                    }
                ))
            })
    }
}

export default FetchGetDetailPago