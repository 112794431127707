import axios from "axios"
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GET_METODO_DE_PAGO_REQUEST =
  "FETCH_GET_METODOS_DE_PAGO_REQUEST"
export const FETCH_GET_METODO_DE_PAGO_SUCCESS =
  "FETCH_GET_METODOS_DE_PAGO_SUCCESS"
export const FETCH_GET_METODO_DE_PAGO_FAILURE =
  "FETCH_GET_METODOS_DE_PAGO_FAILURE"

export const fetchGetMetodosDePagoRequest = () => {
  return {
    type: FETCH_GET_METODO_DE_PAGO_REQUEST
  }
}

export const fetchGetMetodosDePagoSuccess = (cliente) => {
  return {
    type: FETCH_GET_METODO_DE_PAGO_SUCCESS,
    payload: cliente
  }
}

export const fetchGetMetodosDePagoFailure = (error) => {
  return {
    type: FETCH_GET_METODO_DE_PAGO_FAILURE,
    payload: error
  }
}

const FetchGetMetodosDePago = (idCliente, setMetodosData) => {
  return (dispatch) => {
    dispatch(fetchGetMetodosDePagoRequest())
    axios({
      method: "GET",
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}metodo-de-pago/${idCliente}/mis-metodos`
    })
      .then((res) => {
        dispatch(fetchGetMetodosDePagoSuccess(res))
        setMetodosData(res.data)
      })
      .catch((error) => {
        dispatch(fetchGetMetodosDePagoFailure(error.response))
        dispatch(FetchRefreshToken({
          status: error.response?.status,
          fnc: FetchGetMetodosDePago(idCliente, setMetodosData)
      }))
      })
  }
}

export default FetchGetMetodosDePago
