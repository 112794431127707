const initialState = {
    loading: false,
    data: [],
    error: ''
  }
  
  const PutActivarSubReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'PUT_ACTIVAR_SUB_REQUEST':
        return {
          ...state,
          loading: true
        }
      case 'PUT_ACTIVAR_SUB_SUCCESS':
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: ''
        }
      case 'PUT_ACTIVAR_SUB_FAILURE':
        return {
          ...state,
          loading: false,
          data: [],
          error: action.payload
        }
  
      default:
        return state
    }
  }
  
  export default PutActivarSubReducer
  