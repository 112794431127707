import { FETCH_ACTIVAR_AFILIADO_FAILURE, FETCH_ACTIVAR_AFILIADO_REQUEST, FETCH_ACTIVAR_AFILIADO_SUCCESS } from "../actions/PutActivarAfiliadoAction"

const initialState = {
    loading: false,
    afiliado: [],
    error: ""
}

const PutActivarAfiliadoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACTIVAR_AFILIADO_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_ACTIVAR_AFILIADO_SUCCESS:
            return {
                ...state,
                loading: false,
                afiliado: action.payload,
                error: ""
            }
        case FETCH_ACTIVAR_AFILIADO_FAILURE:
            return {
                ...state,
                loading: false,
                afiliado: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default PutActivarAfiliadoReducer