import {
  FETCH_FRONT_VERSION_REQUEST,
  FETCH_FRONT_VERSION_SUCCESS,
  FETCH_FRONT_VERSION_FAILURE
} from '../actions/frontVersionAction'

const initialState = {
  loading: false,
  data: [],
  error: ""
}

const FrontVersionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FRONT_VERSION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case FETCH_FRONT_VERSION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ""
      }
    case FETCH_FRONT_VERSION_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      }

    default:
      return state
  }
}

export default FrontVersionReducer
