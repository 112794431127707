import { toast } from 'react-toastify'
import { Check } from 'react-feather'
import Avatar from '@components/avatar'
import { Fragment } from 'react'

const SuccessProgressToast = ({ body, title }) => (
    <Fragment>
        <div className='toastify-header'>
            <div className='title-wrapper'>
                <Avatar size='sm' color='success' icon={<Check size={12} />} />
                <h6 className='toast-title'>{title}</h6>
            </div>
            <small className='text-muted'></small>
        </div>
        <div className='toastify-body'>
            <span role='img' aria-label='toast-text'>
                {body}
            </span>
        </div>
    </Fragment>
)
export const notifySuccessProgress = (title, body) => toast.success(<SuccessProgressToast body={body} title={title} />, { autoClose: 20000 })