import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_CANCELROL_REQUEST = 'FETCH_CANCELROL_REQUEST'
export const FETCH_CANCELROL_SUCCESS = 'FETCH_CANCELROL_SUCCESS'
export const FETCH_CANCELROL_FAILURE = 'FETCH_CANCELROL_FAILURE'

export const fetchCalcelRolRequest = () => {
    return {
        type: FETCH_CANCELROL_REQUEST
    }
}

export const fetchCalcelRolSuccess = (rol) => {
    return {
        type: FETCH_CANCELROL_SUCCESS,
        payload: rol
    }

}

export const fetchCalcelRolFailure = (error) => {
    return {
        type: FETCH_CANCELROL_FAILURE,
        payload: error
    }
}

const FetchCalcelRol = (data, history) => {
    const { id_roles } = data
    return (dispatch) => {
        dispatch(fetchCalcelRolRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}roles/eliminarRol`,
            data: {
                id_rol: id_roles
            }
        })
            .then(res => {
                dispatch(fetchCalcelRolSuccess(res))
                notifyErrorProgress(
                    "Rol cancelado"
                )
            })
            .catch(error => {
                dispatch(fetchCalcelRolFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchCalcelRol(data, history)
                    }
                ))
            })
    }
}

export default FetchCalcelRol