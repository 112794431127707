import { BACKEND } from "@src/BACKEND"
import axios from "axios"
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_DINERO_REQUEST = 'GET_DINERO_REQUEST'
export const GET_DINERO_SUCCESS = 'GET_DINERO_SUCCESS'
export const GET_DINERO_ERROR = 'GET_DINERO_ERROR'

const getDineroRequest = () => {
  return {
      type: GET_DINERO_REQUEST
  }
}

const getDineroSuccess = (dinero) => {
  return {
      type: GET_DINERO_SUCCESS,
      payload: dinero
  }
}

const getDineroError = (error) => {
  return {
      type: GET_DINERO_ERROR,
      payload: error
  }
}

const GetDinero = (setMoney, idEmpresa) => {
  return (dispatch) => {
    dispatch(getDineroRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}empresa/${idEmpresa}/ingresos`
    })
    .then((response) => {
      const theData = response.data
      const sendTheData = theData.map((usuario) => ({
        id_cliente: usuario.id_usuario_cliente,
        id_suscripcion: usuario.id_suscripcion,
        nombre_plan: usuario.nombre_plan,
        estado: usuario.estado,
        monto_bruto: usuario.monto_bruto,
        monto_neto: usuario.monto_neto,
        nombre_cliente: usuario.nombre_cliente,
        apellido_cliente: usuario.apellido_cliente,
        fecha_cobro: usuario.fecha_cobro,
        id_pago: usuario.id_pago,
        transferencia_enviada: usuario.transferencia_enviada,
        fecha_envio_transferencia: usuario.fecha_envio_transferencia
      }))
      dispatch(getDineroSuccess(theData))
      setMoney(sendTheData)
    })
    .catch((error) => {
      dispatch(getDineroError(error.response))

      dispatch(FetchRefreshToken(
        {
            status: error.response?.status,
            fnc: GetDinero(setMoney, idEmpresa)
        }
    ))
    })
  }
}

export default GetDinero