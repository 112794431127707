import { BACKEND } from "@src/BACKEND"
import axios from "axios"
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_ESTADISTICAS_REQUEST = 'GET_ESTADISTICAS_REQUEST'
export const GET_ESTADISTICAS_SUCCESS = 'GET_ESTADISTICAS_SUCCESS'
export const GET_ESTADISTICAS_ERROR = 'GET_ESTADISTICAS_ERROR'

const getEstadisticasRequest = () => {
  return {
    type: GET_ESTADISTICAS_REQUEST
  }
}

const getEstadisticasSuccess = (estadisticas) => {
  return {
    type: GET_ESTADISTICAS_SUCCESS,
    payload: estadisticas
  }
}

const getEstadisticasError = (error) => {
  return {
    type: GET_ESTADISTICAS_ERROR,
    payload: error
  }
}

const GetEstadisticas = (setEstadisticas, idEmpresa, year) => {
  return (dispatch) => {
    dispatch(getEstadisticasRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}empresa/ver-estadisticas`,
      params: {
        idUsuarioEmpresa: idEmpresa,
        year: year.toString()
      }
    })
      .then((response) => {
        dispatch(getEstadisticasSuccess(response))
        setEstadisticas(response.data)
      })
      .catch((error) => {
        dispatch(getEstadisticasError(error.response))

        dispatch(FetchRefreshToken({
          status: error.response?.status,
          fnc: GetEstadisticas(setEstadisticas, idEmpresa, year)
        }))
      })
  }

}

export default GetEstadisticas