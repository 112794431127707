import {
    GET_DATOS_BAJA_CLIENTE_REQUEST,
    GET_DATOS_BAJA_CLIENTE_SUCCESS,
    GET_DATOS_BAJA_CLIENTE_FAILURE
} from "../actions/getDatosBajaCliente"
  
  const initialState = {
    loading: false,
    datosBajaCliente: [],
    error: ""
  }
  
  const GetDatosBajaClienteReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_DATOS_BAJA_CLIENTE_REQUEST:
        return {
          ...state,
          loading: true
        }
      case GET_DATOS_BAJA_CLIENTE_SUCCESS:
        return {
          ...state,
          loading: false,
          datosBajaCliente: action.payload,
          error: ""
        }
      case GET_DATOS_BAJA_CLIENTE_FAILURE:
        return {
          ...state,
          loading: false,
          datosBajaCliente: [],
          error: action.payload
        }
      default:
        return state
    }
  }
  
  export default GetDatosBajaClienteReducer