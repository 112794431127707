export const FETCH_NAV_USER_DATA_REQUEST = 'FETCH_NAV_USER_DATA_REQUEST'
export const REMOVE_NAV_USER_DATA_REQUEST = "REMOVE_NAV_USER_DATA_REQUEST"

export const fetchNavUserDataRequest = (user, imgRender) => {
    return {
        type: FETCH_NAV_USER_DATA_REQUEST,
        payload: user,
        img: imgRender
    }
}
export const RemoveNavUserDataRequest = () => {
    return {
        type: REMOVE_NAV_USER_DATA_REQUEST
       
    }
}

const NavUserData = (imgRender) => {
    const userData = JSON.parse(localStorage.getItem('userData'))

    return (dispatch) => {
        dispatch(fetchNavUserDataRequest(userData, imgRender))
    }
}

export default NavUserData