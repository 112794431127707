import PageRoutes from './pages'
import NegocioRoutes from './negocio'
import BancoRoutes from './banco'
import PlanRoutes from './plan'
import ProdRoutes from './productos'
import ClientesRoutes from './clientes'
import FiscalRoutes from './fiscal'
import { isUserLoggedIn } from '@utils'

// ** Document title
const TemplateTitle = 'Cobromix'

// ** Default Route
const handleLogin = () => {
    if (isUserLoggedIn() !== null) {
        return '/inicio'
    } else {
        return '/iniciar-sesion'
    }
}

const DefaultRoute = handleLogin()

// ** Merge Routes
const Routes = [
    ...PageRoutes,
    ...NegocioRoutes,
    ...BancoRoutes,
    ...PlanRoutes,
    ...ProdRoutes,
    ...ClientesRoutes,
    ...FiscalRoutes
]

export { DefaultRoute, TemplateTitle, Routes }
