import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Avatar from '@components/avatar'
import { isUserLoggedIn } from '@utils'
import { useDispatch, useSelector } from 'react-redux'
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'
import { User, Power, PieChart, ArrowRightCircle } from 'react-feather'
import LogoutUser from './store/actions'
import { useHistory } from 'react-router'
import FetchUpdateUser from './store/updateUserStore/updateUserAction'
import PutEleccionUsuarioAction from '../../../../redux/actions/eleccion-usuario/updateEleccionUsuarioAction'
import FetchGetEleccionUsuario from '../../../../redux/actions/eleccion-usuario/eleccionUsuarioAction'

const UserDropdown = ({ handleEleccionCliente, eleccionUsuario }) => {
  const location = useLocation()
  const NavUserDataReducer = useSelector(state => state.NavUserDataReducer)
  const [userCliente] = useState(JSON.parse(localStorage.getItem('userCliente')))
  const [user] = useState(JSON.parse(localStorage.getItem('userData')))
  const history = useHistory()
  const dispatch = useDispatch()
  const userData = NavUserDataReducer.user?.usuario
  const [userRol] = useState(JSON.parse(localStorage.getItem('userRol')))
  const [imgProfile, setImgProfile] = useState('')

  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      const { idEmpresaAsociada, idEmpresaUsuario } = userRol?.usuario || {}
      if (idEmpresaAsociada === idEmpresaUsuario) {
        setImgProfile(user?.usuario.imagen_empresa)
      } else if (idEmpresaAsociada !== idEmpresaUsuario) {
        setImgProfile(userRol.imagenEmpresaAsociada)
      }
      dispatch(FetchUpdateUser(user.usuario.id_usuario_empresa))
      if (userData?.estado === "onboarding") {
        history.push("/introduccion")
      }
    } else {
      history.push(`/iniciar-sesion?to=${location.pathname}`)
    }
  }, [])

  const handleToggleEleccion = async () => {
    const nuevaEleccion = eleccionUsuario === 'cliente' ? 'empresa' : 'cliente'
    await dispatch(PutEleccionUsuarioAction(userCliente.id_usuario, nuevaEleccion))
    await dispatch(FetchGetEleccionUsuario(userCliente.id_usuario))
    handleEleccionCliente(nuevaEleccion)
  }

  const userAvatar = userData?.imagen_empresa || false
  return userData ? (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-sm-flex d-none align-items-start'>
          <span className='user-name font-weight-bold mb-0'>{`${userData?.nombre_gerente}`}</span>
          <span className='user-name font-weight-bold mb-0'>{`${userData?.apellido_gerente}`}</span>
        </div>
        {typeof userRol === "object" ? <Avatar img={userRol?.imagenEmpresaAsociada} imgHeight='40' imgWidth='40' /> : NavUserDataReducer?.img ? <Avatar img={NavUserDataReducer?.img} imgHeight='40' imgWidth='40' /> : <Avatar img={userAvatar} imgHeight='40' imgWidth='40' />}
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to='/perfil' className="d-flex justify-content-between align-items-center">
          <div className='d-flex align-items-center'>
            <span className='mr-3'>Perfil</span>
          </div>
          <div>
            <User size={14} />
          </div>
        </DropdownItem>
        <DropdownItem tag={Link} to={eleccionUsuario === "cliente" ? "/inicio" : "/inicio-cliente"} className="d-flex justify-content-between align-items-center" onClick={handleToggleEleccion}>
          <div className='d-flex align-items-center'>
            <span className='mr-2'>{eleccionUsuario === "cliente" ? "Empresa" : "Cliente"}</span>
          </div>
          <div>
            {eleccionUsuario === "cliente" ? <PieChart size={14} /> : <ArrowRightCircle size={14} />}
          </div>
        </DropdownItem>
        <DropdownItem tag={Link} onClick={() => dispatch(LogoutUser(history))} className="d-flex justify-content-between align-items-center">
          <div className='d-flex align-items-center'>
            <span className='mr-3'>Salir</span>
          </div>
          <div>
            <Power size={14} />
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : ""
}

export default UserDropdown
