const initialState = {
    loading: false,
    data: [],
    error: ""
}

const GetSubscriptionListReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_GET_SUBSCRIPCION_LIST_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_GET_SUBSCRIPCION_LIST_SUCCESS':
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: ""
            }
        case 'FETCH_GET_SUBSCRIPCION_LIST_FAILURE':
            return {
                ...state,
                loading: false,
                data: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default GetSubscriptionListReducer