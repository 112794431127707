import {
    GET_VALIDACION_REQUEST,
    GET_VALIDACION_SUCCESS,
    GET_VALIDACION_ERROR
} from "../actions/getEstadoValidacion"

const initialState = {
    loading: false,
    img: [],
    error: ""
}

export default function GetValidacionReducer(state = initialState, action) {
    switch (action.type) {
        case GET_VALIDACION_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_VALIDACION_SUCCESS: {
            return {
                ...state,
                loading: false,
                img: action.payload,
                error: ""
            }
        }
        case GET_VALIDACION_ERROR: {
            return {
                ...state,
                loading: false,
                img: [],
                error: action.payload
            }
        }
        default: return state
    }
}
