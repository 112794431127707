import {
    GET_DATOS_DETALLE_USUARIO_ROL_REQUEST,
    GET_DATOS_DETALLE_USUARIO_ROL_SUCCESS,
    GET_DATOS_DETALLE_USUARIO_ROL_FAILURE
} from "../actions/detalleUsuarioRolAction"
  
  const initialState = {
    loading: false,
    detalleRol: [],
    error: ""
  }
  
  const GetDetalleUsuarioRolReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_DATOS_DETALLE_USUARIO_ROL_REQUEST:
        return {
          ...state,
          loading: true
        }
      case GET_DATOS_DETALLE_USUARIO_ROL_SUCCESS:
        return {
          ...state,
          loading: false,
          detalleRol: action.payload,
          error: ""
        }
      case GET_DATOS_DETALLE_USUARIO_ROL_FAILURE:
        return {
          ...state,
          loading: false,
          detalleRol: [],
          error: action.payload
        }
      default:
        return state
    }
  }
  
  export default GetDetalleUsuarioRolReducer