import { FETCH_CANCELPLAN_FAILURE, FETCH_CANCELPLAN_REQUEST, FETCH_CANCELPLAN_SUCCESS } from "../actions/cancelPlanAction"

const initialState = {
    loading: false,
    plan: [],
    error: ""
}

const CancelPlanReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CANCELPLAN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_CANCELPLAN_SUCCESS:
            return {
                ...state,
                loading: false,
                plan: action.payload,
                error: ""
            }
        case FETCH_CANCELPLAN_FAILURE:
            return {
                ...state,
                loading: false,
                plan: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default CancelPlanReducer