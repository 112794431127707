import {
  GET_DATOS_USER_REQUEST,
  GET_DATOS_USER_SUCCESS,
  GET_DATOS_USER_FAILURE
} from "../actions/datosLoginAction"

const initialState = {
  loading: false,
  user: [],
  error: ""
}
  
const GetUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATOS_USER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_DATOS_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload
      }
    case GET_DATOS_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default: return state
  }
}

export default GetUserReducer