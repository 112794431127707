import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_DATOS_ROLES_REQUEST = 'GET_DATOS_ROLES_REQUEST'
export const GET_DATOS_ROLES_SUCCESS = 'GET_DATOS_ROLES_SUCCESS'
export const GET_DATOS_ROLES_FAILURE = 'GET_DATOS_ROLES_FAILURE'

export const GetDatosRolesRequest = () => {
  return {
    type: GET_DATOS_ROLES_REQUEST
  }
}

export const GetDatosRolesSuccess = (tipo) => {
  return {
    type: GET_DATOS_ROLES_SUCCESS,
    payload: tipo
  }
}

export const GetDatosRolesFailure = (err) => {
  return {
    type: GET_DATOS_ROLES_FAILURE,
    payload: err
  }
}

const GetDatosRoles = (setRoles, idEmpresa) => {
  return (dispatch) => {
    dispatch(GetDatosRolesRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}roles/obtenerRoles?id=${idEmpresa}`
    })
      .then((res) => {
        dispatch(GetDatosRolesSuccess(res.data))
        setRoles(res.data)
      })
      .catch((err) => {
        dispatch(GetDatosRolesFailure(err.response))
        dispatch(FetchRefreshToken(
          {
            status: err.response?.status,
            fnc: GetDatosRoles(setRoles, idEmpresa)
          }
        ))
      })
  }
}

export default GetDatosRoles