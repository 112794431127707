const initialState = {
    loading: false,
    cliente: [],
    error: ""
}

const EditUserDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_EDIT_USER_DATA_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'FETCH_EDIT_USER_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                cliente: action.payload,
                error: ""
            }
        case 'FETCH_EDIT_USER_DATA_FAILURE':
            return {
                ...state,
                loading: false,
                cliente: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default EditUserDataReducer