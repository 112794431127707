import {
  UPDATE_FRONT_VERSION_REQUEST,
  UPDATE_FRONT_VERSION_SUCCESS,
  UPDATE_FRONT_VERSION_FAILURE
} from '../actions/updateFrontVersionAction'

const initialState = {
  loading: false,
  data: [],
  error: ''
}

const UpdateVersionReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FRONT_VERSION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case UPDATE_FRONT_VERSION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ''
      }
    case UPDATE_FRONT_VERSION_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      }

    default:
      return state
  }
}

export default UpdateVersionReducer
