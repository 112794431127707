const initialState = {
    validacion: {}

}

function validacionReducer(state = initialState, action) {
    switch (action.type) {
        case "GET_VALIDACION":
            return {
                validacion: action.payload
            }
        default:
            return state
    }
}
export default validacionReducer
