import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_ASOCIAR_DATOS_BANCARIOS_REQUEST = 'FETCH_ASOCIAR_DATOS_BANCARIOS_REQUEST'
export const FETCH_ASOCIAR_DATOS_BANCARIOS_SUCCESS = 'FETCH_ASOCIAR_DATOS_BANCARIOS_SUCCESS'
export const FETCH_ASOCIAR_DATOS_BANCARIOS_FAILURE = 'FETCH_ASOCIAR_DATOS_BANCARIOS_FAILURE'

export const fetchAsociarDatosBancariosRequest = () => {
    return {
        type: FETCH_ASOCIAR_DATOS_BANCARIOS_REQUEST
    }
}

export const fetchAsociarDatosBancariosSuccess = (prod) => {
    return {
        type: FETCH_ASOCIAR_DATOS_BANCARIOS_SUCCESS,
        payload: prod
    }
}

export const fetchAsociarDatosBancariosFailure = (error) => {
    return {
        type: FETCH_ASOCIAR_DATOS_BANCARIOS_FAILURE,
        payload: error
    }
}

const PutAsociarDatosBancariosAction = (idAfiliado, history, cuit, cbu) => {
    return (dispatch) => {
        dispatch(fetchAsociarDatosBancariosRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}afiliados/empresa/${idAfiliado}/datos-bancarios`,
            data: {
                cuit,
                cbu
            }
        })
            .then(res => {
                dispatch(fetchAsociarDatosBancariosSuccess(res))
                notifySuccessProgress(
                    "Comisión actualizada con éxito"
                )
            })
            .catch(error => {
                dispatch(fetchAsociarDatosBancariosFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: PutAsociarDatosBancariosAction(idAfiliado, history, cuit, cbu)
                    }
                ))
            })
    }
}

export default PutAsociarDatosBancariosAction