import { FETCH_NEW_USER_FAILURE, FETCH_NEW_USER_REQUEST, FETCH_NEW_USER_SUCCESS } from "./actions"

const initialState = {
  loading: false,
  user: [],
  error: ''
}

const SignInReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NEW_USER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case FETCH_NEW_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: ''
      }
    case FETCH_NEW_USER_FAILURE:
      return {
        ...state,
        loading: false,
        user: [],
        error: action.payload
      }

    default:
      return state
  }
}

export default SignInReducer