import { lazy } from 'react'

const NegocioRoutes = [
    {
        path: '/ajustes',
        component: lazy(() => import('@src/pages/options/options')),
        exact: true
    },
    {
        path: '/ajustes/:id',
        component: lazy(() => import('@src/pages/options/options')),
        exact: true
    },
    {
        path: '/ajustes/marca',
        component: lazy(() => import('@src/pages/options/marca/Marca')),
        exact: true
    },
    {
        path: '/ajustes/fiscal',
        component: lazy(() => import('@src/pages/options/fiscal/Fiscal')),
        exact: true
    },
    {
        path: '/ajustes/banco',
        component: lazy(() => import('@src/pages/options/banco/Banco')),
        exact: true
    }
    /* {
        path: '/ajustes/auditoria',
        component: lazy(() => import('@src/pages/negocio/Auditoria'))
    } */
]

export default NegocioRoutes