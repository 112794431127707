import {
  GET_DINERO_REQUEST,
  GET_DINERO_SUCCESS,
  GET_DINERO_ERROR
} from "../actions/DineroActions"

const initialState = {
  loading: false,
  dinero: [],
  error: ""
}

export default function GetDineroReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DINERO_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_DINERO_SUCCESS: {
      return {
        ...state,
        loading: false,
        dinero: action.payload,
        error: ""
      }
    }
    case GET_DINERO_ERROR: {
      return {
        ...state,
        loading: false,
        dinero: [],
        error: action.payload
      }
    }
    default: return state
  }
}
