import {
  PUT_DATOS_FISCALES_REQUEST,
  PUT_DATOS_FISCALES_SUCCESS,
  PUT_DATOS_FISCALES_FAILURE
} from "../actions/updateDatosFiscalAction"

const initialState = {
  loading: false,
  data: [],
  error: ""
}

const PutDatosFiscalesReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUT_DATOS_FISCALES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case PUT_DATOS_FISCALES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ""
      }
    case PUT_DATOS_FISCALES_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default PutDatosFiscalesReducer
