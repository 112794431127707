import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_DATOS_BANCARIOS_REQUEST = 'GET_DATOS_BANCARIOS_REQUEST'
export const GET_DATOS_BANCARIOS_SUCCESS = 'GET_DATOS_BANCARIOS_SUCCESS'
export const GET_DATOS_BANCARIOS_FAILURE = 'GET_DATOS_BANCARIOS_FAILURE'

export const GetDatosBancariosRequest = () => {
  return {
    type: GET_DATOS_BANCARIOS_REQUEST
  }
}

export const GetDatosBancariosSuccess = (data) => {
  return {
    type: GET_DATOS_BANCARIOS_SUCCESS,
    payload: data
  }
}

export const GetDatosBancariosFailure = (err) => {
  return {
    type: GET_DATOS_BANCARIOS_FAILURE,
    payload: err
  }
}

const GetDatosBancarios = (setBankData, idEmpresa, setLoading, setLoadingStatus, setMessageError) => {
  return (dispatch) => {
    dispatch(GetDatosBancariosRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}usuario/empresa/datos-bancarios?id=${idEmpresa}`
    })
      .then((res) => {
        dispatch(GetDatosBancariosSuccess(res))
        const json = res.data
        if (json.data.estado_fiscal === "Activado") {
          setBankData(json.data)
          setLoading(true)
          setLoadingStatus(true)
        } else {
          setLoading(true)
          setMessageError("No existen datos bancarios de la empresa")
          setLoadingStatus(false)
        }
      })
      .catch((err) => {
        dispatch(GetDatosBancariosFailure(err))
        const json = err.response.data
        setLoading(true)
        setMessageError(json.message)
        setLoadingStatus(false)
        dispatch(FetchRefreshToken(
          {
            status: err.response.status,
            fnc: GetDatosBancarios(setBankData, idEmpresa)
          }
      ))
      })
  }
}

export default GetDatosBancarios