import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_DATOS_BAJA_CLIENTE_REQUEST = 'GET_DATOS_BAJA_CLIENTE_REQUEST'
export const GET_DATOS_BAJA_CLIENTE_SUCCESS = 'GET_DATOS_BAJA_CLIENTE_SUCCESS'
export const GET_DATOS_BAJA_CLIENTE_FAILURE = 'GET_DATOS_BAJA_CLIENTE_FAILURE'

export const GetDatosBajaClienteRequest = () => {
  return {
    type: GET_DATOS_BAJA_CLIENTE_REQUEST
  }
}

export const GetDatosBajaClienteSuccess = (tipo) => {
  return {
    type: GET_DATOS_BAJA_CLIENTE_SUCCESS,
    payload: tipo
  }
}

export const GetDatosBajaClienteFailure = (err) => {
  return {
    type: GET_DATOS_BAJA_CLIENTE_FAILURE,
    payload: err
  }
}

const GetDatosBajaCliente = (setBajaCliente, idEmpresa) => {
  return (dispatch) => {
    dispatch(GetDatosBajaClienteRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}usuario/empresa/${idEmpresa}/tipoBajaCliente`
    })
      .then((res) => {
        dispatch(GetDatosBajaClienteSuccess(res.data))
        setBajaCliente(res.data)
      })
      .catch((err) => {
        dispatch(GetDatosBajaClienteFailure(err.response))
        dispatch(FetchRefreshToken(
          {
            status: err.response?.status,
            fnc: GetDatosBajaCliente(setBajaCliente, idEmpresa)
          }
        ))
      })
  }
}

export default GetDatosBajaCliente