import { FETCH_DETALLE_SUSCRIPCION_FAILURE, FETCH_DETALLE_SUSCRIPCION_REQUEST, FETCH_DETALLE_SUSCRIPCION_SUCCESS } from "../actions/getDetailSuscripcionAction"

const initialState = {
    loading: false,
    suscripcion: [],
    error: ""
}

const DetalleSuscripcionReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DETALLE_SUSCRIPCION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_DETALLE_SUSCRIPCION_SUCCESS:
            return {
                ...state,
                loading: false,
                suscripcion: action.payload,
                error: ""
            }
        case FETCH_DETALLE_SUSCRIPCION_FAILURE:
            return {
                ...state,
                loading: false,
                suscripcion: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default DetalleSuscripcionReducer