import { FETCH_DETAILPAGO_REQUEST, FETCH_DETAILPAGO_SUCCESS, FETCH_DETAILPAGO_FAILURE } from "../actions/getDetallePagoClienteAction"

const initialState = {
    loading: false,
    pago: [],
    error: ""
}

const GetDetailPagoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DETAILPAGO_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_DETAILPAGO_SUCCESS:
            return {
                ...state,
                loading: false,
                pago: action.payload,
                error: ""
            }
        case FETCH_DETAILPAGO_FAILURE:
            return {
                ...state,
                loading: false,
                pago: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default GetDetailPagoReducer