import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GET_CLIENTE_MANUAL_REQUEST = 'FETCH_GET_CLIENTE_MANUAL_REQUEST'
export const FETCH_GET_CLIENTE_MANUAL_SUCCESS = 'FETCH_GET_CLIENTE_MANUAL_SUCCESS'
export const FETCH_GET_CLIENTE_MANUAL_FAILURE = 'FETCH_GET_CLIENTE_MANUAL_FAILURE'

export const fetchGetClienteManualRequest = () => {
    return {
        type: FETCH_GET_CLIENTE_MANUAL_REQUEST
    }
}

export const fetchGetClienteManualSuccess = (cliente) => {
    return {
        type: FETCH_GET_CLIENTE_MANUAL_SUCCESS,
        payload: cliente
    }
}

export const fetchGetClienteManualFailure = (error) => {
    return {
        type: FETCH_GET_CLIENTE_MANUAL_FAILURE,
        payload: error
    }
}

const FetchGetClienteManual = (setCliente, id_usuario_cliente) => {
    return (dispatch) => {
        dispatch(fetchGetClienteManualRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}pagoManual/obtenerCliente?id=${id_usuario_cliente}`
        })
            .then(res => {
                dispatch(fetchGetClienteManualSuccess(res))
                setCliente(res.data)
            })
            .catch(error => {
                dispatch(fetchGetClienteManualFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchGetClienteManual(setCliente, id_usuario_cliente)
                    }
                ))
            })
    }
}

export default FetchGetClienteManual