import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const PUT_DATOS_BANCARIOS_REQUEST = 'PUT_DATOS_BANCARIOS_REQUEST'
export const PUT_DATOS_BANCARIOS_SUCCESS = 'PUT_DATOS_BANCARIOS_SUCCESS'
export const PUT_DATOS_BANCARIOS_FAILURE = 'PUT_DATOS_BANCARIOS_FAILURE'

export const PutDatosBancariosRequest = () => {
  return {
    type: PUT_DATOS_BANCARIOS_REQUEST
  }
}

export const PutDatosBancariosSuccess = (data) => {
  return {
    type: PUT_DATOS_BANCARIOS_SUCCESS,
    payload: data
  }
}

export const PutDatosBancariosFailure = (err) => {
  return {
    type: PUT_DATOS_BANCARIOS_FAILURE,
    payload: err
  }
}

const PutDatosBancariosAction = (idEmpresa, cbu, handleToggleFiscalView) => {
  return (dispatch) => {
    dispatch(PutDatosBancariosRequest())
    axios({
      method: 'PUT',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}usuario/empresa/cbu-desactivar?id=${idEmpresa}`
    })
      .then(res => {
        dispatch(PutDatosBancariosSuccess(res))
        if (res.data === "Cbu eliminado con éxito") {
          notifySuccessProgress(
            "CBU eliminado con éxito",
            `La CBU ${cbu} fué eliminada correctamente`
          )
          handleToggleFiscalView()
        }
      })
      .catch(err => {
        dispatch(PutDatosBancariosFailure(err))

        dispatch(FetchRefreshToken(
          {
            status: err.response?.status,
            fnc: PutDatosBancariosAction(idEmpresa, cbu, handleToggleFiscalView)
          }
        ))
      })
  }
}

export default PutDatosBancariosAction
