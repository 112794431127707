import { FETCH_DETAILPRODUCT_FAILURE, FETCH_DETAILPRODUCT_REQUEST, FETCH_DETAILPRODUCT_SUCCESS } from "../actions/detailProductAction"


const initialState = {
    loading: false,
    prod: [],
    error: ""
}

const DetailProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DETAILPRODUCT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_DETAILPRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                prod: action.payload,
                error: ""
            }
        case FETCH_DETAILPRODUCT_FAILURE:
            return {
                ...state,
                loading: false,
                prod: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default DetailProductReducer