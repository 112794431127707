import { FETCH_OBTENER_PLAN_AFILIADO_FAILURE, FETCH_OBTENER_PLAN_AFILIADO_REQUEST, FETCH_OBTENER_PLAN_AFILIADO_SUCCESS } from "../actions/GetPlanAsociadoAfiliadoAction"

const initialState = {
    loading: false,
    planes: [],
    error: ""
}

const ObtenerPlanAfiliadoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_OBTENER_PLAN_AFILIADO_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_OBTENER_PLAN_AFILIADO_SUCCESS:
            return {
                ...state,
                loading: false,
                planes: action.payload,
                error: ""
            }
        case FETCH_OBTENER_PLAN_AFILIADO_FAILURE:
            return {
                ...state,
                loading: false,
                planes: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default ObtenerPlanAfiliadoReducer