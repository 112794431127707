import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GETPLANES_REQUEST = 'FETCH_GETPLANES_REQUEST'
export const FETCH_GETPLANES_SUCCESS = 'FETCH_GETPLANES_SUCCESS'
export const FETCH_GETPLANES_FAILURE = 'FETCH_GETPLANES_FAILURE'

export const fetchGetPlanesRequest = () => {
    return {
        type: FETCH_GETPLANES_REQUEST
    }
}

export const fetchGetPlanesSuccess = (prod) => {
    return {
        type: FETCH_GETPLANES_SUCCESS,
        payload: prod
    }
}

export const fetchGetPlanesFailure = (error) => {
    return {
        type: FETCH_GETPLANES_FAILURE,
        payload: error
    }
}

const FetchGetPlanes = (setPlanes, idUser) => {
    return (dispatch) => {
        dispatch(fetchGetPlanesRequest())
        axios({
            method: 'get',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}plan/ver-planes`,
            params: { idUsuarioEmpresa: idUser }
        })
            .then(res => {
                dispatch(fetchGetPlanesSuccess(res))
                setPlanes(res.data.filter(plan => plan.activo).reverse())
            })
            .catch(error => {
                dispatch(fetchGetPlanesFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchGetPlanes(setPlanes, idUser)
                    }
                ))
            })
    }
}

export default FetchGetPlanes