import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'

export const FETCH_NEWROL_REQUEST = 'FETCH_NEWROL_REQUEST'
export const FETCH_NEWROL_SUCCESS = 'FETCH_NEWROL_SUCCESS'
export const FETCH_NEWROL_FAILURE = 'FETCH_NEWROL_FAILURE'

export const fetchNewRolRequest = () => {
    return {
        type: FETCH_NEWROL_REQUEST
    }
}

export const fetchNewRolSuccess = (rol) => {
    return {
        type: FETCH_NEWROL_SUCCESS,
        payload: rol
    }

}

export const fetchNewRolFailure = (error) => {
    return {
        type: FETCH_NEWROL_FAILURE,
        payload: error
    }
}

const FetchNewRol = (data, history, handleToggleRolesView) => {
    return (dispatch) => {
        dispatch(fetchNewRolRequest())
        axios({
            method: 'post',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}roles/agregarRol`,
            data: {
                nombre_rol: data.nombre_rol,
                id_usuario_empresa: data.id_usuario_empresa,
                inicio: data.inicio,
                clientes: data.clientes,
                planes: data.planes,
                perfil: data.perfil,
                ayuda: data.ayuda,
                actividad: data.actividad,
                vender: data.vender,
                metricas: data.metricas,
                dinero: data.dinero,
                ajustes: data.ajustes
            }
        })
            .then(res => {
                dispatch(fetchNewRolSuccess(res))
                notifySuccessProgress(
                    "Rol creado",
                    `El rol ${data.nombre_rol} fue creado correctamente.`
                )
                handleToggleRolesView()
            })
            .catch(error => {
                dispatch(fetchNewRolFailure(error.response))
                if (error.response.status === 406) notifyErrorProgress(error.response.data)
                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchNewRol(data, handleToggleRolesView)
                    }
                ))
            })
    }
}

export default FetchNewRol