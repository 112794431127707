import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_DATOS_DETALLE_USUARIO_ROL_REQUEST = 'GET_DATOS_DETALLE_USUARIO_ROL_REQUEST'
export const GET_DATOS_DETALLE_USUARIO_ROL_SUCCESS = 'GET_DATOS_DETALLE_USUARIO_ROL_SUCCESS'
export const GET_DATOS_DETALLE_USUARIO_ROL_FAILURE = 'GET_DATOS_DETALLE_USUARIO_ROL_FAILURE'

export const GetDatosDetalleUsuarioRolRequest = () => {
  return {
    type: GET_DATOS_DETALLE_USUARIO_ROL_REQUEST
  }
}

export const GetDatosDetalleUsuarioRolSuccess = (tipo) => {
  return {
    type: GET_DATOS_DETALLE_USUARIO_ROL_SUCCESS,
    payload: tipo
  }
}

export const GetDatosDetalleUsuarioRolFailure = (err) => {
  return {
    type: GET_DATOS_DETALLE_USUARIO_ROL_FAILURE,
    payload: err
  }
}

const GetDatosDetalleUsuarioRol = (setRoles, idUsuarioRol) => {
  return (dispatch) => {
    dispatch(GetDatosDetalleUsuarioRolRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}roles/detalleUsuarioRol?id=${idUsuarioRol}`
    })
      .then((res) => {
        dispatch(GetDatosDetalleUsuarioRolSuccess(res.data))
        setRoles(res.data)
      })
      .catch((err) => {
        dispatch(GetDatosDetalleUsuarioRolFailure(err.response))
        dispatch(FetchRefreshToken(
          {
            status: err.response?.status,
            fnc: GetDatosDetalleUsuarioRol(setRoles, idUsuarioRol)
          }
        ))
      })
  }
}

export default GetDatosDetalleUsuarioRol