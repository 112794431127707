import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GET_CLIENTES_REQUEST = 'FETCH_GET_CLIENTES_REQUEST'
export const FETCH_GET_CLIENTES_SUCCESS = 'FETCH_GET_CLIENTES_SUCCESS'
export const FETCH_GET_CLIENTES_FAILURE = 'FETCH_GET_CLIENTES_FAILURE'

export const fetchGetClientesRequest = () => {
    return {
        type: FETCH_GET_CLIENTES_REQUEST
    }
}

export const fetchGetClientesSuccess = (cliente) => {
    return {
        type: FETCH_GET_CLIENTES_SUCCESS,
        payload: cliente
    }
}

export const fetchGetClientesFailure = (error) => {
    return {
        type: FETCH_GET_CLIENTES_FAILURE,
        payload: error
    }
}

const FetchGetClientes = (setClientes, idEmpresa) => {
    return (dispatch) => {
        dispatch(fetchGetClientesRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}empresa/mis-clientes`,
            params: {idUsuarioEmpresa: idEmpresa}
        })
            .then(res => {
                dispatch(fetchGetClientesSuccess(res))
                setClientes(res.data)
            })
            .catch(error => {
                dispatch(fetchGetClientesFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchGetClientes(setClientes, idEmpresa)
                    }
                ))
            })
    }
}

export default FetchGetClientes