import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_DETAILPRODUCT_REQUEST = 'FETCH_DETAILPRODUCT_REQUEST'
export const FETCH_DETAILPRODUCT_SUCCESS = 'FETCH_DETAILPRODUCT_SUCCESS'
export const FETCH_DETAILPRODUCT_FAILURE = 'FETCH_DETAILPRODUCT_FAILURE'

export const fetchDetailProdRequest = () => {
    return {
        type: FETCH_DETAILPRODUCT_REQUEST
    }
}

export const fetchDetailProdSuccess = (prod) => {
    return {
        type: FETCH_DETAILPRODUCT_SUCCESS,
        payload: prod
    }
}

export const fetchDetailProdFailure = (error) => {
    return {
        type: FETCH_DETAILPRODUCT_FAILURE,
        payload: error
    }
}

const FetchDetailProd = (setNewProd, idProd) => {
    return (dispatch) => {
        dispatch(fetchDetailProdRequest())
        axios({
            method: 'get',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}categoria/detalle`,
            params: { idCategoria: idProd }
        })
            .then(res => {
                dispatch(fetchDetailProdSuccess(res.data))
                setNewProd(res.data)
            })
            .catch(error => {
                dispatch(fetchDetailProdFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchDetailProd(setNewProd, idProd)
                    }
                ))
            })
    }
}

export default FetchDetailProd