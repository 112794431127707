import { FETCH_PAIS_FAILURE, FETCH_PAIS_REQUEST, FETCH_PAIS_SUCCESS } from "./paisAction"

const initialState = {
  loading: false,
  pais: [],
  error: ''
}

const PaisReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAIS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case FETCH_PAIS_SUCCESS:
      return {
        ...state,
        loading: false,
        pais: action.payload,
        error: ''
      }
    case FETCH_PAIS_FAILURE:
      return {
        ...state,
        loading: false,
        pais: [],
        error: action.payload
      }

    default:
      return state
  }
}

export default PaisReducer