import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { prevRuta } from '@utils'
import { toBoolean, getEleccionUsuario } from '../../../../utility/Utils'
import FetchGetEleccionUsuario from '../../../../redux/actions/eleccion-usuario/eleccionUsuarioAction'

export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST'
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS'
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE'

export const fetchLoginRequest = () => {
  return {
    type: FETCH_LOGIN_REQUEST
  }
}

export const fetchLoginSuccess = (user) => {
  return {
    type: FETCH_LOGIN_SUCCESS,
    payload: user
  }
}

export const fetchLoginFailure = (error) => {
  return {
    type: FETCH_LOGIN_FAILURE,
    payload: error
  }
}
const LoginUser = (data, history) => {
    return async (dispatch, getState) => {
    dispatch(fetchLoginRequest())
    try {
      const res = await axios.post(`${BACKEND}usuario/empresa/login`, {
        email: data.email,
        contrasena: data.contrasena
      })

      dispatch(fetchLoginSuccess(res))
      localStorage.setItem('userData', JSON.stringify(res.data))
      localStorage.setItem('userEmpresaToken', JSON.stringify(res.data.token))
      localStorage.setItem('userCliente', JSON.stringify(res.data.cliente))
      localStorage.setItem('Rol', JSON.stringify(res.data.roles))
      await dispatch(FetchGetEleccionUsuario(res.data.usuario.id_usuario))
      await new Promise((resolve) => setTimeout(resolve, 700))
      const state = getState()
      const eleccionUsuario = getEleccionUsuario(state)
      const url_string = location.href
      const url = new URL(url_string)
      const to = url.searchParams.get("to")
      
      if (eleccionUsuario === "cliente" || /checkout/.test(to)) {
        const dataRol = res.data.adminRol
        localStorage.setItem('userRol', JSON.stringify(dataRol))
        prevRuta(history, `/inicio-cliente`)
      } else {
        const userRoles = res.data.roles
        const isAdmin = toBoolean(userRoles)

        if (isAdmin) {
          if (userRoles.length === 1) {
            const dataRol = res.data.adminRol
            localStorage.setItem('userRol', JSON.stringify(dataRol))
            prevRuta(history, `/inicio`)
          } else {
            history.push(`/elegir-rol`)
          }
        } else {
          const dataRol = res.data.adminRol
          localStorage.setItem('userRol', JSON.stringify(dataRol))
          prevRuta(history, `/inicio`)
        }
      }
    } catch (error) {
      dispatch(fetchLoginFailure(error.response))
    }
  }
}

export default LoginUser