import { FETCH_GET_CLIENTE_MANUAL_FAILURE, FETCH_GET_CLIENTE_MANUAL_REQUEST, FETCH_GET_CLIENTE_MANUAL_SUCCESS } from "../actions/getClienteManualAction"


const initialState = {
    loading: false,
    cliente: [],
    error: ""
}

const GetClienteManualReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GET_CLIENTE_MANUAL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_GET_CLIENTE_MANUAL_SUCCESS:
            return {
                ...state,
                loading: false,
                cliente: action.payload,
                error: ""
            }
        case FETCH_GET_CLIENTE_MANUAL_FAILURE:
            return {
                ...state,
                loading: false,
                cliente: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default GetClienteManualReducer