import {
  GET_ACTIVIDAD_REQUEST,
  GET_ACTIVIDAD_SUCCESS,
  GET_ACTIVIDAD_ERROR
} from "../actions/ActividadActions"

const initialState = {
  loading: false,
  dinero: [],
  error: ""
}

export default function GetActividadReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVIDAD_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_ACTIVIDAD_SUCCESS: {
      return {
        ...state,
        loading: false,
        dinero: action.payload,
        error: ""
      }
    }
    case GET_ACTIVIDAD_ERROR: {
      return {
        ...state,
        loading: false,
        dinero: [],
        error: action.payload
      }
    }
    default: return state
  }
}
