import { FETCH_GET_USER_DATA_FAILURE, FETCH_GET_USER_DATA_REQUEST, FETCH_GET_USER_DATA_SUCCESS } from "../../../actions/actions-cliente/perfil-cliente/userDataAction"

const initialState = {
    loading: false,
    cliente: [],
    error: ""
}

const GetUserDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GET_USER_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_GET_USER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                cliente: action.payload,
                error: ""
            }
        case FETCH_GET_USER_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                cliente: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default GetUserDataReducer