import axios from "axios"
import { BACKEND } from "@src/BACKEND"
import { getToken } from "@utils"
import FetchRefreshToken from "@refreshToken"
import { notifySuccessProgress } from '@src/components/Toast/successToast'

export const PUT_ANULAR_SUSCRIPCION_REQUEST =
  "PUT_ANULAR_SUSCRIPCION_REQUEST"
export const PUT_ANULAR_SUSCRIPCION_SUCCESS =
  "PUT_ANULAR_SUSCRIPCION_SUCCESS"
export const PUT_ANULAR_SUSCRIPCION_FAILURE =
  "PUT_ANULAR_SUSCRIPCION_FAILURE"

export const putAnularSuscripcionRequest = () => {
  return {
    type: PUT_ANULAR_SUSCRIPCION_REQUEST
  }
}

export const putAnularSuscripcionSuccess = (suscripcion) => {
  return {
    type: PUT_ANULAR_SUSCRIPCION_SUCCESS,
    payload: suscripcion
  }
}

export const putAnularSuscripcionFailure = (error) => {
  return {
    type: PUT_ANULAR_SUSCRIPCION_FAILURE,
    payload: error
  }
}

const PutAnularSuscripcion = (idSubscription, history, idCliente) => {
  return (dispatch) => {
    dispatch(putAnularSuscripcionRequest())
    axios({
      method: 'PUT',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}empresa/suscripcion/anular`,
      params:{idSuscripcion: idSubscription},
      data: {
        estadoFront: "Cancelada"
      }
    })
      .then(res => {
        dispatch(putAnularSuscripcionSuccess(res))
        notifySuccessProgress("Suscripción cancelada")
        history.push(`/clientes/ver/${idCliente}`)
      })
      .catch(err => {
        dispatch(putAnularSuscripcionFailure(err.response))
        dispatch(
          FetchRefreshToken({
            status: err.response.status,
            fnc: PutAnularSuscripcion(idSubscription)
          })
        )
      })
  }
}

export default PutAnularSuscripcion
