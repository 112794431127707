import { FETCH_EDITROL_FAILURE, FETCH_EDITROL_REQUEST, FETCH_EDITROL_SUCCESS } from "../actions/editRolAction"

const initialState = {
    loading: false,
    rol: [],
    error: ""
}

const EditRolReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EDITROL_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_EDITROL_SUCCESS:
            return {
                ...state,
                loading: false,
                rol: action.payload,
                error: ""
            }
        case FETCH_EDITROL_FAILURE:
            return {
                ...state,
                loading: false,
                rol: [],
                error: action.payload
            }
        default:
            return state
    }
}

export default EditRolReducer