import axios from 'axios'
import { Circle } from 'react-feather'
import { BACKEND } from '@src/BACKEND'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_ELIMINAR_PRODUCTO_REQUEST = 'FETCH_ELIMINAR_PRODUCTO_REQUEST'
export const FETCH_ELIMINAR_PRODUCTO_SUCCESS = 'FETCH_ELIMINAR_PRODUCTO_SUCCESS'
export const FETCH_ELIMINAR_PRODUCTO_FAILURE = 'FETCH_ELIMINAR_PRODUCTO_FAILURE'

export const fetchEliminarProductoRequest = () => {
    return {
        type: FETCH_ELIMINAR_PRODUCTO_REQUEST
    }
}

export const fetchEliminarProductoSuccess = (prod) => {
    return {
        type: FETCH_ELIMINAR_PRODUCTO_SUCCESS,
        payload: prod
    }
}

export const fetchEliminarProductoFailure = (error) => {
    return {
        type: FETCH_ELIMINAR_PRODUCTO_FAILURE,
        payload: error
    }
}

const FetchEliminarProducto = (data, history) => {
    return (dispatch) => {
        dispatch(fetchEliminarProductoRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}categoria/eliminar`,
            data: {
                idCategoria: data.id_categoria,
                estado: 0
            }
        })
            .then(res => {
                dispatch(fetchEliminarProductoSuccess(res.data))
                notifySuccessProgress(
                    "Categoría eliminada",
                    `La categoría ${data.nombre_categoria} fue eliminada con éxito`
                )
                history.push('/planes')
            })
            .catch(error => {
                dispatch(fetchEliminarProductoFailure(error.response))
                if (error.response?.status === 400) {
                    notifyErrorProgress(
                        "Error al eliminar la categoría",
                        <div>
                            <span className='font-weight-bolder'>La categoría no puede ser eliminada porque tiene planes asociados.</span>
                            <div className='mt-25'>
                                {error.response.data.planesAsociados.map((plan, i) => (
                                    <p className='mb-0 d-flex align-items-center' key={i}><Circle className='mr-25' size={10} />{plan.nombre}</p>
                                ))}
                            </div>
                        </div>
                    )
                }
                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchEliminarProducto(data, history)
                    }
                ))
            })
    }
}

export default FetchEliminarProducto