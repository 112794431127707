import axios from 'axios'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'


export const POST_ENVIAR_COMPROBANTE_REQUEST = 'POST_ENVIAR_COMPROBANTE_REQUEST'
export const POST_ENVIAR_COMPROBANTE_SUCCESS = 'POST_ENVIAR_COMPROBANTE_SUCCESS'
export const POST_ENVIAR_COMPROBANTE_FAILURE = 'POST_ENVIAR_COMPROBANTE_FAILURE'


export const postEnviarcomprobanteRequest = () => {
    return {
      type: POST_ENVIAR_COMPROBANTE_REQUEST
    }
  }
  
  export const postEnviarcomprobanteSuccess = (data) => {
    return {
      type: POST_ENVIAR_COMPROBANTE_SUCCESS,
      payload: data
    }
  }
  
  export const postEnviarcomprobanteFailure = (err) => {
    return {
      type: POST_ENVIAR_COMPROBANTE_FAILURE,
      payload: err
    }
  }
  

const postSendInvoiceAction = (data) => {
    return (dispatch) => {
      dispatch(postEnviarcomprobanteRequest())
      axios({
        method: 'POST',
        headers: { Authorization: `Bearer ${getToken()}` },
        url: `${BACKEND}facturacion/enviar-comprobante`,
        data: {
            id:data.id,
            subject:data.subject,
            message:data.message,
            destiny:data.destiny,
            comprobante:data.comprobante
          }
      })
        .then((res) => {
          dispatch(postEnviarcomprobanteSuccess(res.data))
          const json = res.data
          if (json.message === "Comprobante enviado!") notifySuccessProgress("Comprobante enviado con éxito")
          
        })
        .catch(err => {
          dispatch(postEnviarcomprobanteFailure(err.response))
          if (err.response?.status === 500) notifyErrorProgress("Faltan completar datos")
        
          dispatch(FetchRefreshToken(
            {
              status: err.response?.status,
              fnc: postSendInvoiceAction(data)
            }
          ))
        })
      }
    }


export default postSendInvoiceAction