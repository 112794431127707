import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GET_SUSCRIPCION_CLIENTE_REQUEST = 'FETCH_GET_SUSCRIPCION_CLIENTE_REQUEST'
export const FETCH_GET_SUSCRIPCION_CLIENTE_SUCCESS = 'FETCH_GET_SUSCRIPCION_CLIENTE_SUCCESS'
export const FETCH_GET_SUSCRIPCION_CLIENTE_FAILURE = 'FETCH_GET_SUSCRIPCION_CLIENTE_FAILURE'

export const fetchGetSuscripcionClienteRequest = () => {
    return {
        type: FETCH_GET_SUSCRIPCION_CLIENTE_REQUEST
    }
}

export const fetchGetSuscripcionClienteSuccess = (cliente) => {
    return {
        type: FETCH_GET_SUSCRIPCION_CLIENTE_SUCCESS,
        payload: cliente
    }
}

export const fetchGetSuscripcionClienteFailure = (error) => {
    return {
        type: FETCH_GET_SUSCRIPCION_CLIENTE_FAILURE,
        payload: error
    }
}

const FetchGetSuscripcionCliente = (setSuscripciones, idCliente) => {
    return (dispatch) => {
        dispatch(fetchGetSuscripcionClienteRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}suscripcion/${idCliente}/mis-suscripciones`
        })
            .then(res => {
                dispatch(fetchGetSuscripcionClienteSuccess(res))
                setSuscripciones(res.data)
            })
            .catch(error => {
                dispatch(fetchGetSuscripcionClienteFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchGetSuscripcionCliente(setSuscripciones, idCliente)
                    }
                ))
            })
    }
}

export default FetchGetSuscripcionCliente