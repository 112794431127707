import axios from "axios"
import { BACKEND } from "@src/BACKEND"
import { getToken } from "@utils"
import FetchRefreshToken from "@refreshToken"

export const UPDATE_FRONT_VERSION_REQUEST = "UPDATE_FRONT_VERSION_REQUEST"
export const UPDATE_FRONT_VERSION_SUCCESS = "UPDATE_FRONT_VERSION_SUCCESS"
export const UPDATE_FRONT_VERSION_FAILURE = "UPDATE_FRONT_VERSION_FAILURE"

export const updateFrontVersionRequest = () => {
  return {
    type: UPDATE_FRONT_VERSION_REQUEST
  }
}

export const updateFrontVersionSuccess = (data) => {
  return {
    type: UPDATE_FRONT_VERSION_SUCCESS,
    payload: data
  }
}

export const updateFrontVersionFailure = (error) => {
  return {
    type: UPDATE_FRONT_VERSION_FAILURE,
    payload: error
  }
}

const UpdateFrontVersion = (idEmpresa, version) => {
  return (dispatch) => {
    dispatch(updateFrontVersionRequest())
    axios({
      method: 'put',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}version/${idEmpresa}/update`,
      data: {
        newVersion: version
      }
    })
      .then((res) => {
        dispatch(updateFrontVersionSuccess(res))
      })
      .catch((err) => {
        dispatch(updateFrontVersionFailure(err.response))
        dispatch(
          FetchRefreshToken({
            status: err.response.status,
            fnc: UpdateFrontVersion(idEmpresa, version)
          })
        )
      })
  }
}

export default UpdateFrontVersion
