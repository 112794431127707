import {
    FETCH_GET_METODO_DE_PAGO_REQUEST,
    FETCH_GET_METODO_DE_PAGO_SUCCESS,
    FETCH_GET_METODO_DE_PAGO_FAILURE
  } from "../../../actions/actions-cliente/medios-cliente/mediosPagoAction"
  
  const initialState = {
    loading: false,
    metodosDePago: [],
    error: ""
  }
  
  const GetMetodosDePagoReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_GET_METODO_DE_PAGO_REQUEST:
        return {
          ...state,
          loading: true
        }
      case FETCH_GET_METODO_DE_PAGO_SUCCESS:
        return {
          ...state,
          loading: false,
          metodosDePago: action.payload,
          error: ""
        }
      case FETCH_GET_METODO_DE_PAGO_FAILURE:
        return {
          ...state,
          loading: false,
          metodosDePago: [],
          error: action.payload
        }
  
      default:
        return state
    }
  }
  
  export default GetMetodosDePagoReducer
  