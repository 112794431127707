import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GET_COMPROBANTES_REQUEST = 'FETCH_GET_COMPROBANTES_REQUEST'
export const FETCH_GET_COMPROBANTES_SUCCESS = 'FETCH_GET_COMPROBANTES_SUCCESS'
export const FETCH_GET_COMPROBANTES_FAILURE = 'FETCH_GET_COMPROBANTES_FAILURE'

export const fetchGetComprobantesRequest = () => { return { type: FETCH_GET_COMPROBANTES_REQUEST } }
export const fetchGetComprobantesSuccess = (data) => { return { type: FETCH_GET_COMPROBANTES_SUCCESS, payload: data } }
export const fetchGetComprobantesFailure = (error) => { return { type: FETCH_GET_COMPROBANTES_FAILURE, payload: error } }

const GetComprobantesAction = (idEmpresa) => {
  return (dispatch) => {
    dispatch(fetchGetComprobantesRequest())
    axios({
      method: "get",
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}facturacion/comprobantes`,
      params:{idUsuarioEmpresa: idEmpresa}
    })
      .then(res => {
        dispatch(fetchGetComprobantesSuccess(res))
      })
      .catch(err => {
        dispatch(fetchGetComprobantesFailure(err))
        
        dispatch(FetchRefreshToken(
          { 
            status: err.response?.status,
            fnc: GetComprobantesAction(idEmpresa)
          }
        ))
      })
  }
}

export default GetComprobantesAction