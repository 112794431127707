const initialState = { loading: false, data: [], error: "" }

const GetRolEstadoInicioReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'GET_ROL_ESTADO_INICIO_ACTION_REQUEST':
      return {
        ...state,
        loading: true
      }
    case 'GET_ROL_ESTADO_INICIO_ACTION_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ""
      }
    case 'GET_ROL_ESTADO_INICIO_ACTION_FAILURE':
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      }

    default:
      return state
  }
}

export default GetRolEstadoInicioReducer
