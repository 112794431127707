import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GET_AYUDA_CLIENTES_REQUEST = 'FETCH_GET_AYUDA_CLIENTES_REQUEST'
export const FETCH_GET_AYUDA_CLIENTES_SUCCESS = 'FETCH_GET_AYUDA_CLIENTES_SUCCESS'
export const FETCH_GET_AYUDA_CLIENTES_FAILURE = 'FETCH_GET_AYUDA_CLIENTES_FAILURE'

export const fetchGetAyudaClientesRequest = () => {
    return {
        type: FETCH_GET_AYUDA_CLIENTES_REQUEST
    }
}

export const fetchGetAyudaClientesSuccess = (ayuda) => {
    return {
        type: FETCH_GET_AYUDA_CLIENTES_SUCCESS,
        payload: ayuda
    }
}

export const fetchGetAyudaClientesFailure = (error) => {
    return {
        type: FETCH_GET_AYUDA_CLIENTES_FAILURE,
        payload: error
    }
}

const FetchGetAyudaClientes = (setAyuda, idCliente) => {
    return (dispatch) => {
        dispatch(fetchGetAyudaClientesRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}ayuda/ayudaClientes`
        })
            .then(res => {
                dispatch(fetchGetAyudaClientesSuccess(res.data))
                setAyuda(res.data)
            })
            .catch(error => {
                dispatch(fetchGetAyudaClientesFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchGetAyudaClientes(setAyuda, idCliente)
                    }
                ))
            })
    }
}

export default FetchGetAyudaClientes
