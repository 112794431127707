import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_DATOS_USER_REQUEST = 'GET_DATOS_USER_REQUEST'
export const GET_DATOS_USER_SUCCESS = 'GET_DATOS_USER_SUCCESS'
export const GET_DATOS_USER_FAILURE = 'GET_DATOS_USER_FAILURE'

export const GetDatosUserRequest = () => {
  return {
    type: GET_DATOS_USER_REQUEST
  }
}

export const GetDatosUserSuccess = (tipo) => {
  return {
    type: GET_DATOS_USER_SUCCESS,
    payload: tipo
  }
}

export const GetDatosUserFailure = (err) => {
  return {
    type: GET_DATOS_USER_FAILURE,
    payload: err
  }
}

const GetDatosUser = (setRoles, idEmpresa) => {
  return (dispatch) => {
    dispatch(GetDatosUserRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}roles/${idEmpresa}/obtener-datos-roles`
    })
    .then((res) => {
      dispatch(GetDatosUserSuccess(res))
      setRoles(res.data.roles)
    })
    .catch((err) => {
      dispatch(GetDatosUserFailure(err))
      dispatch(FetchRefreshToken(
        {
          status: err.response?.status,
          fnc: GetDatosUser(setRoles, idEmpresa)
        }
      ))
    })
  }
}

export default GetDatosUser