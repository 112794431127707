const initialState = { loading: false, datosBancarios: [], error: '' }

const PutNombreURLReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PUT_NOMBRE_URL_REQUEST': return { ...state, loading: true }
    case 'PUT_NOMBRE_URL_SUCCESS': return { ...state, loading: false, dataURL: action.payload, error: '' }
    case 'PUT_NOMBRE_URL_FAILURE': return { ...state, loading: false, dataURL: [], error: action.payload }
    default: return state
  }
}

export default PutNombreURLReducer