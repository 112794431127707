import {
    PUT_DATOS_BAJA_CLIENTE_REQUEST,
    PUT_DATOS_BAJA_CLIENTE_SUCCESS,
    PUT_DATOS_BAJA_CLIENTE_FAILURE
} from '../actions/putBajaClienteAction'

    const initialState = {
    loading: false,
    datosBajaCliente: [],
    error: ""
}

const PutDatosBajaClienteReducer = (state = initialState, action) => {
    switch (action.type) {
        case PUT_DATOS_BAJA_CLIENTE_REQUEST:
        return {
            ...state,
            loading: true
        }
        case PUT_DATOS_BAJA_CLIENTE_SUCCESS:
        return {
            ...state,
            loading: false,
            datosBajaCliente: action.payload,
            error: ""
        }
        case PUT_DATOS_BAJA_CLIENTE_FAILURE:
        return {
            ...state,
            loading: false,
            datosBajaCliente: [],
            error: action.payload
        }
        default:
        return state
    }
}

export default PutDatosBajaClienteReducer