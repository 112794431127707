import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'
import ability from './configs/acl/ability'
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'
import { AbilityContext } from './utility/context/Can'
import { ErrorFallback } from './components/ErrorBoundary/errorFallback'
import Spinner from './@core/components/spinner/Fallback-spinner'
import './@core/components/ripple-button'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'
import 'react-perfect-scrollbar/dist/css/styles.css'
import '@styles/react/libs/toastify/toastify.scss'
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import * as serviceWorker from './serviceWorker'
import App from './App'

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <AbilityContext.Provider value={ability}>
        <ThemeContext>
          <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => { }}>
            <App />
          </ErrorBoundary>
          <ToastContainer newestOnTop />
        </ThemeContext>
      </AbilityContext.Provider>
    </Suspense>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
