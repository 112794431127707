import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'

export const PUT_DATOS_BAJA_CLIENTE_REQUEST = 'PUT_DATOS_BAJA_CLIENTE_REQUEST'
export const PUT_DATOS_BAJA_CLIENTE_SUCCESS = 'PUT_DATOS_BAJA_CLIENTE_SUCCESS'
export const PUT_DATOS_BAJA_CLIENTE_FAILURE = 'PUT_DATOS_BAJA_CLIENTE_FAILURE'

export const PutDatosBajaClienteRequest = () => {
  return {
    type: PUT_DATOS_BAJA_CLIENTE_REQUEST
  }
}

export const PutDatosBajaClienteSuccess = (tipo) => {
  return {
    type: PUT_DATOS_BAJA_CLIENTE_SUCCESS,
    payload: tipo
  }
}

export const PutDatosBajaClienteFailure = (err) => {
  return {
    type: PUT_DATOS_BAJA_CLIENTE_FAILURE,
    payload: err
  }
}

const PutDatosBajaCliente = (newTipo, idEmpresa, value, setSelect) => {
  return (dispatch) => {
    dispatch(PutDatosBajaClienteRequest())
    axios({
      method: 'PUT',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}usuario/empresa/${idEmpresa}/bajaCliente`,
      data: {
        tipo: newTipo
      }
    })
      .then((res) => {
        dispatch(PutDatosBajaClienteSuccess(res))
        notifySuccessProgress("Modificado con éxito", `Ahora utiliza el método de baja de cliente ${value}`)
      })
      .catch((err) => {
        dispatch(PutDatosBajaClienteFailure(err.response))
        dispatch(FetchRefreshToken(
          {
            status: err.response?.status,
            fnc: PutDatosBajaCliente(newTipo, idEmpresa, value, setSelect)
          }
        ))
        notifyErrorProgress("Método en uso", `El método de baja de cliente ${value} ya está en uso`)
        setSelect(true)
      })
  }
}

export default PutDatosBajaCliente