import { GET_CLIENTE_REQUEST, GET_CLIENTE_FAILURE, GET_CLIENTE_SUCCES } from "../actions/getComprobantesClienteAction"

const initialState = {
  loading: false,
  comprobantes: [],
  error: ""
}

const GetComprobanteClienteReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLIENTE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_CLIENTE_SUCCES:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ""
      }
    case GET_CLIENTE_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      }
    default: return state
  }
}

export default GetComprobanteClienteReducer
