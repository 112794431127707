import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_COMISION_AFILIADO_REQUEST = 'FETCH_COMISION_AFILIADO_REQUEST'
export const FETCH_COMISION_AFILIADO_SUCCESS = 'FETCH_COMISION_AFILIADO_SUCCESS'
export const FETCH_COMISION_AFILIADO_FAILURE = 'FETCH_COMISION_AFILIADO_FAILURE'

export const fetchComisionAfiliadoRequest = () => {
    return {
        type: FETCH_COMISION_AFILIADO_REQUEST
    }
}

export const fetchComisionAfiliadoSuccess = (prod) => {
    return {
        type: FETCH_COMISION_AFILIADO_SUCCESS,
        payload: prod
    }
}

export const fetchComisionAfiliadoFailure = (error) => {
    return {
        type: FETCH_COMISION_AFILIADO_FAILURE,
        payload: error
    }
}

const PutComisionAfiliadoAction = (idEmpresa, idAfiliado, nuevaComision,  history) => {
    return (dispatch) => {
        dispatch(fetchComisionAfiliadoRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}afiliados/empresa/${idEmpresa}/${idAfiliado}/comision`,
            data: {
                comision: nuevaComision
            }
        })
            .then(res => {
                dispatch(fetchComisionAfiliadoSuccess(res))
                notifySuccessProgress(
                    "Comisión actualizada con éxito"
                )
            })
            .catch(error => {
                dispatch(fetchComisionAfiliadoFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: PutComisionAfiliadoAction(idEmpresa, idAfiliado, nuevaComision, history)
                    }
                ))
            })
    }
}

export default PutComisionAfiliadoAction