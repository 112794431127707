import { lazy } from 'react'

const FiscalRoutes = [
    {
        path: '/ajustes/fiscal/nuevo-cuit',
        component: lazy(() => import('@src/pages/options/fiscal/nuevoCuit'))
    }

]

export default FiscalRoutes