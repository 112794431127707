import {
    GET_IMAGENES_VALIDACION_REQUEST,
    GET_IMAGENES_VALIDACION_SUCCESS,
    GET_IMAGENES_VALIDACION_ERROR
  } from "../actions/getDniAction"
  
  const initialState = {
    loading: false,
    imagenes: [],
    error: ""
  }
  
  export default function GetDniReducer(state = initialState, action) {
    switch (action.type) {
      case GET_IMAGENES_VALIDACION_REQUEST: {
        return {
          ...state,
          loading: true
        }
      }
      case GET_IMAGENES_VALIDACION_SUCCESS: {
        return {
          ...state,
          loading: false,
          imagenes: action.payload,
          error: ""
        }
      }
      case GET_IMAGENES_VALIDACION_ERROR: {
        return {
          ...state,
          loading: false,
          imagenes: [],
          error: action.payload
        }
      }
      default: return state
    }
  }
  