import { FETCH_SEND_NEW_PASS_FAILURE, FETCH_SEND_NEW_PASS_REQUEST, FETCH_SEND_NEW_PASS_SUCCESS } from "../action/sendNewPassAction"

const initialState = {
    loading: false,
    data: [],
    error: ""
}

const SendNewPassReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SEND_NEW_PASS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_SEND_NEW_PASS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: ""
            }
        case FETCH_SEND_NEW_PASS_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default SendNewPassReducer