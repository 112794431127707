import { GET_VALIDATION_MENU_REQUEST, GET_VALIDATION_MENU_SUCCESS, GET_VALIDATION_MENU_FAILURE } from '../actions/GetValidationMenuAction'

const initialState = { loading: false, data: [], error: "" }

const GetValidationMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VALIDATION_MENU_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_VALIDATION_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ""
      }
    case GET_VALIDATION_MENU_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default GetValidationMenuReducer