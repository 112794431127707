import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GET_FACTURA_REQUEST = 'FETCH_GET_FACTURA_REQUEST'
export const FETCH_GET_FACTURA_SUCCESS = 'FETCH_GET_FACTURA_SUCCESS'
export const FETCH_GET_FACTURA_FAILURE = 'FETCH_GET_FACTURA_FAILURE'

export const fetchGetFacturaRequest = () => {
    return {
        type: FETCH_GET_FACTURA_REQUEST
    }
}

export const fetchGetFacturaSuccess = (factura) => {
    return {
        type: FETCH_GET_FACTURA_SUCCESS,
        payload: factura
    }
}

export const fetchGetFacturaFailure = (error) => {
    return {
        type: FETCH_GET_FACTURA_FAILURE,
        payload: error
    }
}

export function getFactura(id_factura) {
    return (dispatch) => {
        dispatch(fetchGetFacturaRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}facturacion/comprobante`,
            params:{idFactura: id_factura}
        })
            .then(res => {
                dispatch(fetchGetFacturaSuccess(res.data))
                setClientes(res.data)
            })
            .catch(error => {
                // dispatch(fetchGetFacturaFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: getFactura(id_factura)
                    }
                ))
            })
    }
}

export default getFactura