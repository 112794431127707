import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { toast } from 'react-toastify'
import { Check } from 'react-feather'
import Avatar from '@components/avatar'

export const FETCH_SEND_NEW_PASS_REQUEST = 'FETCH_SEND_NEW_PASS_REQUEST'
export const FETCH_SEND_NEW_PASS_SUCCESS = 'FETCH_SEND_NEW_PASS_SUCCESS'
export const FETCH_SEND_NEW_PASS_FAILURE = 'FETCH_SEND_NEW_PASS_FAILURE'

const ToastSuccess = () => (
    <div className='toastify-header pb-0'>
        <div className='title-wrapper'>
            <Avatar size='sm' color='success' icon={<Check />} />
            <h6 className='toast-title'>¡Contraseña modificada correctamente!</h6>
        </div>
    </div>
)

const handlePassChange = () => {
    toast.success(<ToastSuccess />, {
        autoClose: 3000,
        hideProgressBar: false,
        closeButton: true
    })
}

export const fetchSendNewPassRequest = () => {
    return {
        type: FETCH_SEND_NEW_PASS_REQUEST
    }
}

export const fetchSendNewPassSuccess = (data) => {
    return {
        type: FETCH_SEND_NEW_PASS_SUCCESS,
        payload: data
    }
}

export const fetchSendNewPassFailure = (error) => {
    return {
        type: FETCH_SEND_NEW_PASS_FAILURE,
        payload: error
    }
}

const FetchSendNewPass = (password, id, history) => {
    return (dispatch) => {
        dispatch(fetchSendNewPassRequest())
        axios({
            method: 'PUT',
            url: `${BACKEND}usuario/cambiar-contrasena`,
            data: {
                contrasena: password.newPass,
                confirmarContrasena: password.confirmPass,
                id_usuario: id
            }
        })
            .then(res => {
                dispatch(fetchSendNewPassSuccess(res))
                handlePassChange()
                history.push('/iniciar-sesion')
            })
            .catch(error => {
                dispatch(fetchSendNewPassFailure(error.response))
            })
    }
}

export default FetchSendNewPass