import axios from "axios"
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GET_ELECCION_USUARIO_REQUEST =
  "FETCH_GET_ELECCION_USUARIO_REQUEST"
export const FETCH_GET_ELECCION_USUARIO_SUCCESS =
  "FETCH_GET_ELECCION_USUARIO_SUCCESS"
export const FETCH_GET_ELECCION_USUARIO_FAILURE =
  "FETCH_GET_ELECCION_USUARIO_FAILURE"

export const fetchGetEleccionUsuarioRequest = () => {
  return {
    type: FETCH_GET_ELECCION_USUARIO_REQUEST
  }
}

export const fetchGetEleccionUsuarioSuccess = (usuario) => {
  return {
    type: FETCH_GET_ELECCION_USUARIO_SUCCESS,
    payload: usuario
  }
}

export const fetchGetEleccionUsuarioFailure = (error) => {
  return {
    type: FETCH_GET_ELECCION_USUARIO_FAILURE,
    payload: error
  }
}

const FetchGetEleccionUsuario = (id) => {
  return (dispatch) => {
    dispatch(fetchGetEleccionUsuarioRequest())
    axios({
      method: "GET",
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}usuario/eleccion/${id}`
    })
      .then((res) => {
        dispatch(fetchGetEleccionUsuarioSuccess(res.data.eleccionPorDefecto))
    
      })
      .catch((error) => {
        dispatch(fetchGetEleccionUsuarioFailure(error.response))
        dispatch(FetchRefreshToken({
          status: error.response?.status,
          fnc: FetchGetEleccionUsuario(id)
      }))
      })
  }
}

export default FetchGetEleccionUsuario
