import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import FetchRefreshToken from '@refreshToken'
import { getToken } from '@utils'


export const PutEleccionUsuarioRequest = () => { return { type: 'PUT_ELECCION_USUARIO_REQUEST' } }

export const PutEleccionUsuarioSuccess = (eleccion) => { return { type: 'PUT_ELECCION_USUARIO_SUCCESS', payload: eleccion } }

export const PutEleccionUsuarioFailure = (err) => { return { type: 'PUT_ELECCION_USUARIO_FAILURE', payload: err } }

const PutEleccionUsuarioAction = (id, eleccion) => {
  return (dispatch) => {
    dispatch(PutEleccionUsuarioRequest())
    axios({
      method: 'put',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}usuario/eleccion/${id}`,
      data: { eleccion }
    })
      .then(res => {
        const nuevaEleccion = res.data.eleccion
        dispatch(PutEleccionUsuarioSuccess(nuevaEleccion))

      })
      .catch(err => {
        dispatch(PutEleccionUsuarioFailure(err))
        dispatch(FetchRefreshToken({
          status: err.response?.status,
          fnc: PutEleccionUsuarioAction(id)
        }))
      })
  }
}

export default PutEleccionUsuarioAction
