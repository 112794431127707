import {
    GET_DATOS_USUARIO_ROL_REQUEST,
    GET_DATOS_USUARIO_ROL_SUCCESS,
    GET_DATOS_USUARIO_ROL_FAILURE
} from "../actions/getUsuariosRol"
  
  const initialState = {
    loading: false,
    roles: [],
    error: ""
  }
  
  const GetUsuarioRolReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_DATOS_USUARIO_ROL_REQUEST:
        return {
          ...state,
          loading: true
        }
      case GET_DATOS_USUARIO_ROL_SUCCESS:
        return {
          ...state,
          loading: false,
          roles: action.payload,
          error: ""
        }
      case GET_DATOS_USUARIO_ROL_FAILURE:
        return {
          ...state,
          loading: false,
          roles: [],
          error: action.payload
        }
      default:
        return state
    }
  }
  
  export default GetUsuarioRolReducer