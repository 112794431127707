const initialState = {
  loading: false,
  cobros: [],
  error: ""
}

export default function GetNewDineroReducer (state = initialState, action) {
  switch (action.type) {
    case 'NEW_GET_DINERO_REQUEST': return { ...state, loading: true }
    case 'NEW_GET_DINERO_SUCCESS': return { ...state, loading: false, cobros: action.payload, error: '' }
    case 'NEW_GET_DINERO_ERROR': return { ...state, loading: false, cobros: [], error: action.payload }
    default: return state
  }
}
