import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_GET_QR_REQUEST = 'FETCH_GET_QR_REQUEST'
export const FETCH_GET_QR_SUCCESS = 'FETCH_GET_QR_SUCCESS'
export const FETCH_GET_QR_FAILURE = 'FETCH_GET_QR_FAILURE'

export const fetchGetQrRequest = () => {
    return {
        type: FETCH_GET_QR_REQUEST
    }
}

export const fetchGetQrSuccess = (qr) => {
    return {
        type: FETCH_GET_QR_SUCCESS,
        payload: qr
    }
}

export const fetchGetQrFailure = (error) => {
    return {
        type: FETCH_GET_QR_FAILURE,
        payload: error
    }
}

const FetchGetQr = (setQr, idEmpresa) => {
    return (dispatch) => {
        dispatch(fetchGetQrRequest())
        axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}qr/${idEmpresa}/codigos-asociados`
        })
            .then(res => {
                dispatch(fetchGetQrSuccess(res))
                setQr(res.data)
            })
            .catch(error => {
                dispatch(fetchGetQrFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchGetQr(setQr, idEmpresa)
                    }
                ))
            })
    }
}

export default FetchGetQr