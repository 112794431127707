import { BACKEND } from "@src/BACKEND"
import axios from "axios"
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_FECHA_REQUEST = 'GET_FECHA_REQUEST'
export const GET_FECHA_SUCCESS = 'GET_FECHA_SUCCESS'
export const GET_FECHA_ERROR = 'GET_FECHA_ERROR'

const getFechaAltaRequest = () => {
  return {
    type: GET_FECHA_REQUEST
  }
}

const getFechaAltaSuccess = (data) => {
  return {
    type: GET_FECHA_SUCCESS,
    payload: data
  }
}

const getFechaAltaError = (error) => {
  return {
    type: GET_FECHA_ERROR,
    payload: error
  }
}

const GetFechaAlta = (id_empresa) => {
  return (dispatch) => {
    dispatch(getFechaAltaRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}empresa/obtener-fecha-alta?idUsuarioEmpresa=${id_empresa}`
    })
      .then((res) => {
        dispatch(getFechaAltaSuccess(res.data))
      })
      .catch((error) => {
        dispatch(getFechaAltaError(error.response))

        dispatch(FetchRefreshToken(
          {
            status: error.response?.status,
            fnc: GetFechaAlta(id_empresa)
          }
        ))
      })
  }
}

export default GetFechaAlta