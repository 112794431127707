import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'

export const FETCH_INPUT_BAJA_CLIENTE_REQUEST = 'FETCH_INPUT_BAJA_CLIENTE_REQUEST'
export const FETCH_INPUT_BAJA_CLIENTE_SUCCESS = 'FETCH_INPUT_BAJA_CLIENTE_SUCCESS'
export const FETCH_INPUT_BAJA_CLIENTE_FAILURE = 'FETCH_INPUT_BAJA_CLIENTE_FAILURE'

export const fetchInputBajaClienteRequest = () => {
    return {
        type: FETCH_INPUT_BAJA_CLIENTE_REQUEST
    }
}

export const fetchInputBajaClienteSuccess = (prod) => {
    return {
        type: FETCH_INPUT_BAJA_CLIENTE_SUCCESS,
        payload: prod
    }
}

export const fetchInputBajaClienteFailure = (error) => {
    return {
        type: FETCH_INPUT_BAJA_CLIENTE_FAILURE,
        payload: error
    }
}

const FetchInputBajaCliente = (newTipo, idEmpresa, value, setSkeleton, handleToggleFacturacionView, setSelect) => {
    return (dispatch) => {
        dispatch(fetchInputBajaClienteRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}usuario/empresa/${idEmpresa}/modificarBaja`,
            data: {
                texto: newTipo.texto
            }
        })
            .then((res) => {
                dispatch(fetchInputBajaClienteSuccess(res))
                notifySuccessProgress("Modificado con éxito")
                setSkeleton(true)
                handleToggleFacturacionView()
            })
            .catch((err) => {
                dispatch(fetchInputBajaClienteFailure(err.response))
                dispatch(FetchRefreshToken(
                    {
                        status: err.response?.status,
                        fnc: FetchInputBajaCliente(newTipo, idEmpresa, value, setSkeleton, handleToggleFacturacionView, setSelect)
                    }
                ))
                notifyErrorProgress("Método en uso")
                setSelect(true)
            })
    }
}

export default FetchInputBajaCliente