import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_CANCELUSUARIOROL_REQUEST = 'FETCH_CANCELUSUARIOROL_REQUEST'
export const FETCH_CANCELUSUARIOROL_SUCCESS = 'FETCH_CANCELUSUARIOROL_SUCCESS'
export const FETCH_CANCELUSUARIOROL_FAILURE = 'FETCH_CANCELUSUARIOROL_FAILURE'

export const fetchCalcelUsuarioRolRequest = () => {
    return {
        type: FETCH_CANCELUSUARIOROL_REQUEST
    }
}

export const fetchCalcelUsuarioRolSuccess = (usuarioRol) => {
    return {
        type: FETCH_CANCELUSUARIOROL_SUCCESS,
        payload: usuarioRol
    }

}

export const fetchCalcelUsuarioRolFailure = (error) => {
    return {
        type: FETCH_CANCELUSUARIOROL_FAILURE,
        payload: error
    }
}

const FetchCalcelUsuarioRol = (data, history) => {
    return (dispatch) => {
        dispatch(fetchCalcelUsuarioRolRequest())
        axios({
            method: 'PUT',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}roles/eliminarUsuarioRol`,
            data: {
                id_usuario_roles: data.id_usuario_roles
            }
        })
            .then(res => {
                dispatch(fetchCalcelUsuarioRolSuccess(res))
                notifyErrorProgress(
                    "UsuarioRol cancelado"
                )
            })
            .catch(error => {
                dispatch(fetchCalcelUsuarioRolFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchCalcelUsuarioRol(data, history)
                    }
                ))
            })
    }
}

export default FetchCalcelUsuarioRol