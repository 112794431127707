import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_CLIENTE_REQUEST = 'GET_CLIENTE_REQUEST'
export const GET_CLIENTE_SUCCES = 'GET_CLIENTE_SUCCES'
export const GET_CLIENTE_FAILURE = 'FETCH_GET_CLIENTES_FAILURE'

export const GetComprobanteClienteRequest = () => {
  return {
      type: GET_CLIENTE_REQUEST
  }
}

export const GetComprobanteClienteSuccess = (comprobante) => {
  return {
      type: GET_CLIENTE_SUCCES,
      payload: comprobante
  }
}

export const GetComprobanteClienteFailure = (error) => {
  return {
      type: GET_CLIENTE_FAILURE,
      payload: error
  }
}

const GetComprobanteClienteAction = (setComprobantes, idEmpresa, idCliente) => {
  return (dispatch) => {
    dispatch(GetComprobanteClienteRequest())
    axios({
      method: "GET",
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}facturacion/${idCliente}/comprobantesCliente`
    })
      .then(res => {
        dispatch(GetComprobanteClienteSuccess(res))
        setComprobantes(res.data)
      })
      .catch(error => {
        dispatch(GetComprobanteClienteFailure(error))
        
        dispatch(FetchRefreshToken(
          {
            status: error.response?.status,
            fnc: GetComprobanteClienteAction(setComprobantes, idEmpresa, idCliente)
          }
        ))
      })
    }
}

export default GetComprobanteClienteAction
