import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import UpdateFrontVersion from '../../../home/store/actions/updateFrontVersionAction'
import { getToken } from "@utils"
import FetchRefreshToken from "@refreshToken"

export const GET_VERSION_REQUEST = 'GET_VERSION_REQUEST'
export const GET_VERSION_SUCCESS = 'GET_VERSION_SUCCESS'
export const GET_VERSION_FAILURE = 'GET_VERSION_FAILURE'

export const getVersionRequest = () => {
  return {
    type: GET_VERSION_REQUEST
  }
}

export const getVersionSuccess = (version) => {
  return {
    type: GET_VERSION_SUCCESS,
    payload: version
  }
}

export const getVersionFailure = (error) => {
  return {
    type: GET_VERSION_FAILURE,
    payload: error
  }
}

const AppVersion = (localVersion, idEmpresa) => {
  return (dispatch) => {
    dispatch(getVersionRequest())
    axios({
      method: 'get',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}version/back`
    })
      .then(res => {
        const bVersion = res.data.version
        if (bVersion === localVersion) dispatch(getVersionSuccess(bVersion))
        if (bVersion !== localVersion) {
          dispatch(getVersionSuccess(bVersion))
          dispatch(UpdateFrontVersion(idEmpresa, bVersion))
          window.location.reload()
        }
      })
      .catch(err => {
        dispatch(getVersionFailure(err.response))
        dispatch(
          FetchRefreshToken({
            status: err.response.status,
            fnc: AppVersion(localVersion, idEmpresa)
          })
        )
      })
  }
}

export default AppVersion