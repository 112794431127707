import {
  POST_DATOS_FISCALES_REQUEST,
  POST_DATOS_FISCALES_SUCCESS,
  POST_DATOS_FISCALES_FAILURE
} from '../actions/postDatosFiscalesAction'

const initialState = {
  loading: false,
  datosFiscales: [],
  error: ""
}

const PostDatosFiscalesReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_DATOS_FISCALES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case POST_DATOS_FISCALES_SUCCESS:
      return {
        ...state,
        loading: false,
        datosFiscales: action.payload,
        error: ""
      }
    case POST_DATOS_FISCALES_FAILURE:
      return {
        ...state,
        loading: false,
        datosFiscales: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default PostDatosFiscalesReducer