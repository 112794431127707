import { POST_ESTADO_VENDER_REQUEST, POST_ESTADO_VENDER_SUCCESS, POST_ESTADO_VENDER_FAILURE } from '../actions/PostEstadoVenderAction'

const initialState = { loading: false, data: [], error: "" }

const PostEstadoVenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_ESTADO_VENDER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case POST_ESTADO_VENDER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ""
      }
    case POST_ESTADO_VENDER_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default PostEstadoVenderReducer