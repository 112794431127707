import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_VALIDATION_MENU_REQUEST = 'GET_VALIDATION_MENU_REQUEST'
export const GET_VALIDATION_MENU_SUCCESS = 'GET_VALIDATION_MENU_SUCCESS'
export const GET_VALIDATION_MENU_FAILURE = 'GET_VALIDATION_MENU_FAILURE'

export const GetValidationMenuRequest = () => {
  return {
    type: GET_VALIDATION_MENU_REQUEST
  }
}

export const GetValidationMenuSuccess = (tipo) => {
  return {
    type: GET_VALIDATION_MENU_SUCCESS,
    payload: tipo
  }
}

export const GetValidationMenuFailure = (err) => {
  return {
    type: GET_VALIDATION_MENU_FAILURE,
    payload: err
  }
}

const GetValidationMenuAction = (setViewState, empresa) => {
  return (dispatch) => {
    dispatch(GetValidationMenuRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}vender/validacion-datos?id=${empresa}`
    })
    .then((res) => {
      dispatch(GetValidationMenuSuccess(res))
      setViewState(res.data.detail.validacion)
    })
    .catch((err) => {
      dispatch(GetValidationMenuFailure(err))
      dispatch(FetchRefreshToken(
        {
          status: err.response?.status,
          fnc: GetValidationMenuAction(empresa)
        }
      ))
    })
  }
}

export default GetValidationMenuAction