import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const FETCH_NEWPLAN_REQUEST = 'FETCH_NEWPLAN_REQUEST'
export const FETCH_NEWPLAN_SUCCESS = 'FETCH_NEWPLAN_SUCCESS'
export const FETCH_NEWPLAN_FAILURE = 'FETCH_NEWPLAN_FAILURE'

export const fetchNewPlanRequest = () => {
    return {
        type: FETCH_NEWPLAN_REQUEST
    }
}

export const fetchNewPlanSuccess = (plan) => {
    return {
        type: FETCH_NEWPLAN_SUCCESS,
        payload: plan
    }

}

export const fetchNewPlanFailure = (error) => {
    return {
        type: FETCH_NEWPLAN_FAILURE,
        payload: error
    }
}

const FetchNewPlan = (data, history) => {
    return (dispatch) => {
        dispatch(fetchNewPlanRequest())
        axios({
            method: 'post',
            headers: { Authorization: `Bearer ${getToken()}` },
            url: `${BACKEND}plan/crear`,

            data: {
                nombre: data.nombre,
                frecuencia: data.frecuencia,
                precio: data.precio,
                detalle: data.detalle,
                idCategoria: data.idCategoria,
                idUsuarioEmpresa: data.idUsuarioEmpresa,
                imagen: data.imagen,
                duracionPrueba: data.duracion_prueba_gratis,
                costoInscripcion: {
                    precioInscripcion: data.precio_inscripcion
                  },
                fechaFacturacion: {
                    diaFacturacion: data.dia_facturacion,
                    mesFacturacion: data.mes_facturacion,
                    pagoMesVencido: data.pago_mes_vencido
                },
                duracionPlan: {
                    cuotas: data.cuotas,
                    duracionPlanDesde: data.duracion_plan_desde,
                    duracionPlanHasta: data.duracion_plan_hasta
                },
                fechaPrimerCobro: {
                    primer_cobro: data.primer_cobro
                },
                idProximoPlan: null,
                mora: 0,
                oferta: data.oferta,
                ofertaDesde: data.oferta_desde,
                ofertaHasta: data.oferta_hasta,
                descuento: data.descuento
            }
        })
            .then(res => {
                dispatch(fetchNewPlanSuccess(res))
                notifySuccessProgress(
                    "Plan creado",
                    `El plan ${data.nombre} fue creado correctamente.`
                )
                history.push(`/plan/ver/${res.data.id_plan}`)
            })
            .catch(error => {
                dispatch(fetchNewPlanFailure(error.response))

                dispatch(FetchRefreshToken(
                    {
                        status: error.response?.status,
                        fnc: FetchNewPlan(data, history)
                    }
                ))
            })
    }
}

export default FetchNewPlan