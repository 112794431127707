import { FETCH_GET_COMPROBANTES_REQUEST, FETCH_GET_COMPROBANTES_SUCCESS, FETCH_GET_COMPROBANTES_FAILURE } from "../actions/getComprobantesAction"
const initialState = { loading: false, data: [], error: "" }

const GetComprobantesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_COMPROBANTES_REQUEST: return { ...state, loading: true }
    case FETCH_GET_COMPROBANTES_SUCCESS: return { ...state, loading: false, data: action.payload, error: "" }
    case FETCH_GET_COMPROBANTES_FAILURE: return { ...state, loading: false, data: [], error: action.payload }
    default: return state
  }
}

export default GetComprobantesReducer