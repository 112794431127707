import axios from 'axios'

const getPais = () => {
    return axios.get('https://ipapi.co/json/')
}

export const FETCH_PAIS_REQUEST = 'FETCH_PAIS_REQUEST'
export const FETCH_PAIS_SUCCESS = 'FETCH_PAIS_SUCCESS'
export const FETCH_PAIS_FAILURE = 'FETCH_PAIS_FAILURE'

export const fetchPaisRequest = () => {
    return {
        type: FETCH_PAIS_REQUEST
    }
}

export const fetchPaisSuccess = pais => {
    return {
        type: FETCH_PAIS_SUCCESS,
        payload: pais
    }
}

export const fetchPaisFailure = error => {
    return {
        type: FETCH_PAIS_FAILURE,
        payload: error
    }
}

export const fetchPais = (setPaisEmpresa) => {
    return (dispatch) => {
        dispatch(fetchPaisRequest())
        getPais() 
            .then(response => {
                const pais = response.data.country_name
                dispatch(fetchPaisSuccess(pais))
                setPaisEmpresa(pais)
            })
            .catch(error => {
                const errorMsg = error.message
                dispatch(fetchPaisFailure(errorMsg))
            })
    }
}

export default fetchPais
