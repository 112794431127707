import { BACKEND } from "@src/BACKEND"
import axios from "axios"
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_ACTIVIDAD_REQUEST = 'GET_ACTIVIDAD_REQUEST'
export const GET_ACTIVIDAD_SUCCESS = 'GET_ACTIVIDAD_SUCCESS'
export const GET_ACTIVIDAD_ERROR = 'GET_ACTIVIDAD_ERROR'

const getActividadRequest = () => {
  return {
      type: GET_ACTIVIDAD_REQUEST
  }
}

const getActividadSuccess = (dinero) => {
  return {
      type: GET_ACTIVIDAD_SUCCESS,
      payload: dinero
  }
}

const getActividadError = (error) => {
  return {
      type: GET_ACTIVIDAD_ERROR,
      payload: error
  }
}

const GetActividad = (setMoney, idEmpresa) => {
  return (dispatch) => {
    dispatch(getActividadRequest())
    axios({
      method: 'GET',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}empresa/actividad`,
      params: {idUsuarioEmpresa: idEmpresa}
    })
    .then((response) => {
      const theData = response.data
      const sendTheData = theData.map((usuario) => ({
        id_cliente: usuario.id_usuario_cliente,
        id_suscripcion: usuario.id_suscripcion,
        nombre_plan: usuario.nombre_plan,
        estado: usuario.estado,
        monto_bruto: usuario.monto_bruto,
        monto_neto: usuario.monto_neto,
        nombre_cliente: usuario.nombre_cliente,
        apellido_cliente: usuario.apellido_cliente,
        fecha_cobro: usuario.fecha_cobro,
        id_pago: usuario.id_pago,
        transferencia_enviada: usuario.transferencia_enviada,
        reintento_cobro : usuario.reintento_cobro,
        intentos: usuario.intentos,
        forma_pago: usuario.forma_pago
      }))
      dispatch(getActividadSuccess(theData))
      setMoney(sendTheData)
    })
    .catch((error) => {
      dispatch(getActividadError(error.response))

      dispatch(FetchRefreshToken(
        {
            status: error.response?.status,
            fnc: GetActividad(setMoney, idEmpresa)
        }
    ))
    })
  }
}

export default GetActividad