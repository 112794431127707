import { FETCH_GET_RECEIPT_REQUEST, FETCH_GET_RECEIPT_SUCCESS, FETCH_GET_RECEIPT_FAILURE } from "../actions/getReceiptAction"
const initialState = { loading: false, data: [], error: "" }

const getReceiptReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GET_RECEIPT_REQUEST: return { ...state, loading: true }
    case FETCH_GET_RECEIPT_SUCCESS: return { ...state, loading: false, data: action.payload, error: "" }
    case FETCH_GET_RECEIPT_FAILURE: return { ...state, loading: false, data: [], error: action.payload }
    default: return state
  }
}

export default getReceiptReducer