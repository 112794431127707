import axios from 'axios'

export const FETCH_MAIL_REGISTRO_EMPRESA_REQUEST = 'FETCH_MAIL_REGISTRO_EMPRESA_REQUEST'
export const FETCH_MAIL_REGISTRO_EMPRESA_SUCCESS = 'FETCH_MAIL_REGISTRO_EMPRESA_SUCCESS'
export const FETCH_MAIL_REGISTRO_EMPRESA_FAILURE = 'FETCH_MAIL_REGISTRO_EMPRESA_FAILURE'

export const fetchMailRegistroEmpresaRequest = () => {
    return {
        type: FETCH_MAIL_REGISTRO_EMPRESA_REQUEST
    }
}

export const fetchMailRegistroEmpresaSuccess = (user) => {
    return {
        type: FETCH_MAIL_REGISTRO_EMPRESA_SUCCESS,
        payload: user
    }
}

export const fetchMailRegistroEmpresaFailure = (error) => {
    return {
        type: FETCH_MAIL_REGISTRO_EMPRESA_FAILURE,
        payload: error
    }
}

const MailRegistroEmpresa = (data) => {
    return (dispatch) => {
        dispatch(fetchMailRegistroEmpresaRequest())
        axios({
            method: 'POST',
            url: '/api/v2/groups/110205128/subscribers',
            headers: {
                Accept: 'application/json',
                'X-MailerLite-ApiDocs': 'true',
                'Content-Type': 'application/json',
                'X-MailerLite-ApiKey': 'c85e16ca60b00a261fe4e66bc348aef6',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'origin, content-type, accept'
            },
            data: {
                email: data.email,
                resubscribe: false,
                autoresponders: true,
                type: 'null',
                name: `${data.nombre} ${data.apellido}`
            }
        })
            .then(res => {
                dispatch(fetchMailRegistroEmpresaSuccess(res))
            })
            .catch(error => {
                dispatch(fetchMailRegistroEmpresaFailure(error.response))
            })
    }
}

export default MailRegistroEmpresa