import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { notifySuccessProgress } from '@src/components/Toast/successToast'
import { notifyErrorProgress } from '@src/components/Toast/failedToast'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const POST_DATOS_BANCARIOS_REQUEST = 'POST_DATOS_BANCARIOS_REQUEST'
export const POST_DATOS_BANCARIOS_SUCCESS = 'POST_DATOS_BANCARIOS_SUCCESS'
export const POST_DATOS_BANCARIOS_FAILURE = 'POST_DATOS_BANCARIOS_FAILURE'

export const PostDatosBancariosRequest = () => {
  return {
    type: POST_DATOS_BANCARIOS_REQUEST
  }
}

export const PostDatosBancariosSuccess = (data) => {
  return {
    type: POST_DATOS_BANCARIOS_SUCCESS,
    payload: data
  }
}

export const PostDatosBancariosFailure = (err) => {
  return {
    type: POST_DATOS_BANCARIOS_FAILURE,
    payload: err
  }
}

const PostDatosBancarios = (cbu, cuit, idEmpresa, handleToggleBancoView, setSelect) => {
  return (dispatch) => {
    dispatch(PostDatosBancariosRequest())
    axios({
      method: 'POST',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}usuario/empresa/cbu?id=${idEmpresa}&cbu=${cbu}&cuit=${cuit}`
    })
      .then(res => {
        dispatch(PostDatosBancariosSuccess(res))
        const json = res.data
        if (json.message === "Datos bancarios agregados con éxito") notifySuccessProgress("CBU Agregada", `La CBU ${cbu} fue agregada correctamente`)
        if (json.message === "CBU activada con éxito") notifySuccessProgress("CBU Activada", `La CBU ${cbu} fue activada nuevamente`)
        handleToggleBancoView()
      })
      .catch(err => {
        dispatch(PostDatosBancariosFailure(err))
        const json = err.response.data
        if (json.message === "Alguno de los datos ingresado no es correcto") notifyErrorProgress("Alguno de los datos no es correcto", `La CBU ${cbu} no se pudo agregar al sistema`)
        setSelect(true)
        dispatch(FetchRefreshToken(
          {
            status: err.response.status,
            fnc: PostDatosBancarios(cbu, cuit, idEmpresa, handleToggleBancoView, setSelect)
          }
        ))
      })
  }
}

export default PostDatosBancarios
