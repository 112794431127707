import axios from 'axios'
import { BACKEND } from '@src/BACKEND'
import { getToken } from '@utils'
import FetchRefreshToken from '@refreshToken'

export const GET_DATA_VALIDATION_REQUEST = 'GET_DATA_VALIDATION_REQUEST'
export const GetDataValidationRequest = () => { return { type: GET_DATA_VALIDATION_REQUEST } }

export const GET_DATA_VALIDATION_SUCCESS = 'GET_DATA_VALIDATION_SUCCESS'
export const GetDataValidationSuccess = (data) => { return { type: GET_DATA_VALIDATION_SUCCESS, payload: data } }

export const GET_DATA_VALIDATION_FAILURE = 'GET_DATA_VALIDATION_FAILURE'
export const GetDataValidationFailure = (err) => { return { type: GET_DATA_VALIDATION_FAILURE, payload: err } }

const GetDataValidationAction = (
  empresa,
  setLoading,
  setStateNombre,
  setStateLogo,
  setStateCuit,
  setStateCbu,
  setCategoriaPlan,
  setStateGlobalState,
  setStateSubTitle,
  setViewState,
  setValidacionPerfil
) => {
  return (dispatch) => {
    dispatch(GetDataValidationRequest())
    axios({ 
      method: 'get',
      headers: { Authorization: `Bearer ${getToken()}` },
      url: `${BACKEND}vender/validacion-datos?id=${empresa}`
    })
      .then(res => {
        dispatch(GetDataValidationSuccess(res))
        const json = res.data.detail
        if (json.nombre === 1) setStateNombre(true)
        if (json.logo === 1) setStateLogo(true)
        if (json.cuit === 1) setStateCuit(true)
        if (json.cbu === 1) setStateCbu(true)
        if (json.categorias >= 1 && json.planes >= 1) setCategoriaPlan(true)
        if (json.validacion === 1) setValidacionPerfil(true)
        const state = res.data.view

        if (state === true) {
          setStateGlobalState(true)
          setStateSubTitle(true)
        }
        const venderState = res.data.button
        if (venderState === true && state === true) setViewState(true)
        else setViewState(false)
        setLoading(true)
      })
      .catch(err => {
        dispatch(GetDataValidationFailure(err))

        dispatch(FetchRefreshToken(
          {
            status: err.response?.status,
            fnc: GetDataValidationAction(
              empresa,
              setLoading,
              setStateNombre,
              setStateLogo,
              setStateCuit,
              setStateCbu,
              setCategoriaPlan,
              setStateGlobalState,
              setStateSubTitle,
              setViewState
            )
          }
        ))
      })
  }
}

export default GetDataValidationAction