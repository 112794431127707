import { FETCH_GET_FACTURA_REQUEST, FETCH_GET_FACTURA_SUCCESS, FETCH_GET_FACTURA_FAILURE } from "../actions/getInvoiceClienteAction"

const initialState = {
    loading: false,
    factura: [],
    error: ""
}

const getFacturaReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GET_FACTURA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_GET_FACTURA_SUCCESS:
            return {
                ...state,
                loading: false,
                factura: action.payload,
                error: ""
            }
        case FETCH_GET_FACTURA_FAILURE:
            return {
                ...state,
                loading: false,
                factura: [],
                error: action.payload
            }

        default:
            return state
    }
}

export default getFacturaReducer