import { GET_DATA_VALIDATION_REQUEST, GET_DATA_VALIDATION_SUCCESS, GET_DATA_VALIDATION_FAILURE } from '../actions/GetDataValidationAction'

const initialState = { loading: false, data: [], error: "" }

const GetDataValidationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA_VALIDATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case GET_DATA_VALIDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ""
      }
    case GET_DATA_VALIDATION_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload
      }
    default:
      return state
  }
}

export default GetDataValidationReducer